import React, { useEffect } from "react"
import styled from 'styled-components';
import { gsap } from "gsap"

const AdditionalInfoStyles = styled.p`
    font-size: ${({ normalSize }) => normalSize ? 'unset' : '75%!important'};
    ${props => props.marginTopAndBot && 'margin: 1.5rem 0'};
    ${props => props.noMargin && 'unset'};
    ${props => props.normalMargin && '2rem 15px 0'};
    @supports(backdrop-filter: blur(15px)) {
        backdrop-filter: blur(5px);
        background: #36275663;
    }
    background: rgba(38,38,65,0.8);
    border: 2px solid var(--darkPurple);
    padding: 2rem;
    max-width: 75ch;
    border-radius: 1em;
    color: var(--gray);
    margin: 2rem auto 0;
    text-align: start;
    
    button, a {
        position: relative;
        background: none;
        border: none;
        color: var(--primary);
        font: inherit;
        cursor: pointer;
        padding: 0;
        text-decoration: none;
        
        &::after {
            position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
            transform: scaleX(0);
            transition: transform .3s ease-out;
        }
        
        &:hover {
            color: gray;
        }
        
        &:hover::after {
            transform: scaleX(1);
        }
    }
    
    @media (max-width: 980px) {
        display: ${({ showOnlyOnDesktop }) => showOnlyOnDesktop && 'none'};
    }
`;

const AdditionalInfo = ({ children, showOnlyOnDesktop, marginTopAndBot, noMargin, normalMargin }) => {

    useEffect(() => {
        const Q = gsap.utils.toArray('.additional_info');
        gsap.set(Q, { duration: 2, ease: 'power4.out' })
        // gsap.to(Q, { opacity: 0 });

        Q.forEach((el, index) => {
            gsap.fromTo('.additional_info', { autoAlpha: 0 }, { autoAlpha: 1, delay: .5 });
        });

        return () => {
            Q.forEach((el, index) => {
                gsap.to('.additional_info', { autoAlpha: 0 });
            });
        }
    }, []);

    return (
        <AdditionalInfoStyles className="additional_info" showOnlyOnDesktop={showOnlyOnDesktop} marginTopAndBot={marginTopAndBot} noMargin={noMargin} normalMargin={normalMargin}>
            {children}
        </AdditionalInfoStyles>
    )
}

export default AdditionalInfo;