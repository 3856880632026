import React from 'react';
import BriefQuestion from "../../../Questions/BriefQuestion"
import { briefData } from "./data-seed"

export function AnythingElse({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.anythingElse}
        stateKey="anythingElse"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function BrandResources({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.brand_resources.question}
        hint={briefData.common.brand_resources.hint}
        stateKey="brandResources"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function DesignTastes({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.design_tastes.question}
        hint={briefData.common.design_tastes.hint}
        stateKey="designTastes"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function ConcurrencyWebsites({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.concurrency_websites.question}
        hint={briefData.common.concurrency_websites.hint}
        stateKey="concurrencyWebsites"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}