import React from "react"
import { websiteData as data } from "./data-seed"
import ValuationQuestion from "../../Questions/ValuationQuestion"

export function Pages() {
    return <ValuationQuestion
        question="valform_website.Pages"
        answers={data.answersPages}
        stateKey="priceWebsitePages"
        backToWebsiteType
    />
}

export function Form() {
    return <ValuationQuestion
        question="valform_website.Form"
        answers={data.answersForm}
        stateKey="priceWebsiteForm"
    />
}

export function Consultation() {
    return <ValuationQuestion
        question="valform_website.Consultation"
        answers={data.answersConsultation}
        stateKey="priceWebsiteConsultation"
    />
}

export function Blog() {
    return <ValuationQuestion
        question="valform_website.Blog"
        answers={data.answersBlog}
        stateKey="priceWebsiteBlog"
    />
}

export function Newsletter() {
    return <ValuationQuestion
        question="valform_website.Newsletter"
        answers={data.answersNewsletter}
        stateKey="priceWebsiteNewsletter"
    />
}

export function Chat() {
    return <ValuationQuestion
        question="valform_website.Chat"
        answers={data.answersChat}
        stateKey="priceWebsiteChat"
    />
}

export function Languages() {
    return <ValuationQuestion
        question="valform_website.Languages"
        answers={data.answersLanguages}
        stateKey="multiplierWebsiteLanguages"
    />
}

export function Cms() {
    return <ValuationQuestion
        question="valform_website.CMS"
        answers={data.answersCMS}
        stateKey="multiplierWebsiteCMS"
    />
}

export function DarkMode() {
    return <ValuationQuestion
        question="valform_website.DarkMode"
        answers={data.answersDarkMode}
        stateKey="multiplierWebsiteDarkMode"
    />
}

export function Project() {
    return <ValuationQuestion
        question="valform_website.Project"
        answers={data.answersProject}
        stateKey="multiplierWebsiteProject"
    />
}

export function Animations() {
    return <ValuationQuestion
        question="valform_common.Animations"
        answers={data.answersAnimations}
        stateKey="multiplierWebsiteAnimations"
        wide
    />
}