import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next";
import FormContext from "../FormContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import DialogType from "../components/DialogType";
import { TextInputStyles } from '../components';
import { ButtonButton } from "../components/Button/ButtonButton"
import ButtonTip from "../components/ButtonTip";
import Error from '../../../../static/error.svg';
import { Reset, NavigationWrapper, Pagination } from "../components/Navigation/index"
import ScrollProgressIndicator from "../../ScrollProgressIndicator"
import NavigateSteps from "../components/Navigation/NavigateSteps"

const Mail = ({ currentStep, allSteps }) => {

    const { t } = useTranslation(['valform']);
    const { state, setState, windowGlobal, nextStep, getAllSteps } = useContext(FormContext);
    let firstName = state.name.split(' ')[0];

    const { register, handleSubmit, formState: { errors } } = useForm();

    const cache = windowGlobal.localStorage.getItem('email');
    useEffect(() => {
        if(cache) setState({...state, email: cache})
    }, []);

    const onSubmit = (data) => {
        if (data) {
            windowGlobal.localStorage.setItem('email', data.email);
            setState({...state, email: data.email});
            nextStep();
        }
    };

    const emailField = register('email', {
        required: t('valform:valform_utils.missing_field'),
        maxLength: {
            value: 40,
            message: `${t('valform:valform_utils.max_length')} 40`
        },
        pattern: {
            value: /\S+@\S+\.\S+/,
            // value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
            message: t('valform:valform_utils.incorrect_email')
        }
    });

    return (
        <>
            <DialogType>
                <TextInputStyles>
                    <label htmlFor="email">
                        {firstName}, {t('valform:valform_personal_data.email')}
                    </label>
                    <input
                        type="email"
                        placeholder={t('valform:valform_utils.type_here')}
                        className={ errors.email && 'shake' }
                        name="email"
                        autoComplete="on"
                        defaultValue={state.email || cache}
                        onChange={(e) => {
                            emailField.onChange(e);
                            setState({
                                ...state,
                                email: e.target.value
                            })
                            windowGlobal.localStorage.setItem('email', e.target.value);
                        }}
                        ref={emailField.ref}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="email"
                        message={t('valform:valform_utils.missing_field')}
                        render={({message}) => <small><Error/> {message}</small>}
                    />
                </TextInputStyles>
                <TextInputStyles marginTop>
                    <ButtonButton alignLeft send onClick={handleSubmit(onSubmit)}>
                        {t('valform:valform_utils.next')}
                    </ButtonButton>
                    <ButtonTip visible={state.email}/>
                </TextInputStyles>
            </DialogType>
            <NavigationWrapper
                onTop={
                    <ScrollProgressIndicator targetValue={currentStep} maxValue={allSteps} />
                }
            >
                <Reset />
                {/*<PrevStepSubmitStep disabled={errors.email || state.email === ''} submitAnswer={handleSubmit(onSubmit)} />*/}
                <NavigateSteps prevAndSubmit disabledProps={errors.email || state.email === ''} submitAnswer={handleSubmit(onSubmit)} />
                <Pagination currentStep={currentStep} allSteps={allSteps ? allSteps : getAllSteps()} />
            </NavigationWrapper>
        </>
    );
};


export default Mail;