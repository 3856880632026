import styled  from 'styled-components';

export const ValuationFormBackgroundImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    border-radius: inherit;
    
    
  
    @media (min-width: 768px) {
        background-attachment: fixed;
    }
    
    &:before, &:after {
        filter: var(--valFormBrightness);
    }
`;