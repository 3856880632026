import React from 'react';
import { useTranslation } from "react-i18next";
import styled from 'styled-components';

const Legend = styled.legend`
    font-size: 80%;
    color: var(--white);
    text-align: left;
    visibility: ${({ visible }) => visible ? 'visible' : 'hidden'};
    opacity: ${({ visible }) => visible? '1' : '0'};
    transition: visibility 3s, opacity .3s ease-in-out;
    margin-top: 10px;
    
    span {
        font-weight: bold;
    }
    
    @media (max-width: 1024px) {
        display: none;
    }
`;

const ButtonTip = ({ visible }) => {

    const { t } = useTranslation(['valform']);

    return (
        <Legend
            visible={visible}
            dangerouslySetInnerHTML={{ __html: t('valform:valform_utils.button_tip') }}
        />
    )
};

export default ButtonTip;