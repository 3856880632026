import styled from 'styled-components';
import {RightArrow} from "./ArrowRight";

export const LeftArrow = styled(RightArrow)`
    ${RightArrow};
    transform: rotate(180deg);
    
    path {
        fill: var(--white);
    }

    &:hover {
        transform: rotate(180deg) scale(1.2);
    }
`;