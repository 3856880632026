import styled from 'styled-components';

export const InfoStyles = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0 15px 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 680px;
    opacity: 0;
    ${props => props.hidden && 'opacity: 0'};
    ${props => props.alignLeft && 'text-align: left'};
    ${props => props.marginTopMinus && 'margin-top: -4rem'};
    
    a {
        color: inherit;
        text-decoration: underline;
        text-decoration-thickness: 3px;
        text-decoration-color: var(--primary);
        text-decoration-skip-ink: none;
    }

    .estimated_price {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        p {
            width: unset;
        }
    }

    &.center {
        display: flex;
        align-items: center;  
        text-align: center;
    }
    
    .container {
        margin-bottom: 3rem;
        ${props => props.welcome && 'text-align: start'};
    }
    
    .buttons_wrapper {
        display: flex;
        width: fit-content;
        margin: 25px auto 0;
               
        button:nth-child(2) {
            margin-left: 40px
        }
    }
    
    .margin40 {
        margin-bottom: 1.5rem;
    }
    
    .fontsize120 {
        font-size: 120%!important;
    }
    
    .important_info {
        font-weight: bold;
    }
    
    .extra_info {
        color: var(--gray);
        margin-top: 5px;
    }
    
    .additional_info {
        color: var(--gray);
        font-size: 70%;
    }
    
    .gray {
        color: var(--gray);
    }
    
    .summary {
        margin: 0;
        font-weight: bold;
    }
    
    h1 {
        font-family: 'Montserrat', sans-serif;
        color: var(--white);
        text-align: center;
    }
    
    p, h3, label {
        color: var(--white);
        width: 100%;
    }
    
    .summary_heading {
        font-weight: bold;
        border-bottom: 2px solid var(--primary);
    }
    
    p.extra_msg {
        max-height: 10ch;
        overflow: hidden;
    }
    
    span.price {
        display: block;
        margin-top: .5rem;
        font-size: 150%;
        font-weight: bold;
        color: white;
        text-decoration: underline;
        text-decoration-color: var(--advSvg);
    }
    
    span {
        color: red;
        font-size: 80%;
        padding: 0 0 0 5px;
    }
    
    label {
        margin-left: 5px;
    }
    
    label.small {
        align-self: center;
        font-size: 80%;
        font-weight: normal;
        line-height: 1;
        color: var(--gray);
    }
    
    .subscribe_to_newsletter {
        margin-top: 1rem;
    }
    
    .checkbox_wrapper {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: .75rem 0 3rem 0;
        position: relative;
        
        .city {
            display: none;
        }
    }
    
    small {
        display: flex;
            align-items: center;
        position: absolute;
            top: 100%;
        color: var(--red);
        line-height: 1;
        margin-top: 10px;
        transition: .3s ease-in-out;
        
        &:hover {
          
        }
    
        svg {
            fill: var(--red);
            max-width: 21px;
            margin-right: 5px;
            display: flex;
            align-items: self-start;
        }
  }
    
`;