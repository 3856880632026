import styled from 'styled-components';

export const TextInputStyles = styled.div`
    position: relative;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    width: ${({ wide }) => wide ? '80%' : '45%'};
    text-align: center;
    margin-top: ${({ marginTop }) => marginTop ? '2rem' : 'unset'};
    
    @media (max-width: 768px) {
        width: 100%;
        padding: ${({ noPadding }) => noPadding ? 'unset' : '0 15px'};
        
        input {
            width: 100%;
        }
    }
    
    p, h3 {
        color: var(--white);
    }
    
    input {
        border: none;
        background: none;
        border-bottom: 1px solid var(--primary);
        border-radius: 0;
        outline: none;
        transition: box-shadow .15s ease-in;
        color: var(--white);
        padding: 0 0 3px 5px;
        width: 100%;
    
        &:focus {
            box-shadow: 0 1px var(--primary);
        }
    }
    
    textarea {
        border: none;
        background: none;
        border-bottom: 1px solid var(--primary);
        border-radius: 0;
        color: white;
        outline: none;
        display: block;
        width: 100%;
        overflow-wrap: break-word;
        resize: none;
        overflow: hidden;
        transition: box-shadow 0.1s ease-out 0s;
        box-shadow: rgba(255, 255, 255, 0.3) 0 1px;
    
        &:focus {
            box-shadow: 0 1px var(--primary);
        }
    }
    
    label {
    //position: absolute;
    //top: 8px;
    //left: 10px;
        transform-origin: left;
        transition: transform .3s ease-in-out;
        color: var(--white);
        margin-bottom: 20px;
        font-weight: bold;
        font-size: 120%;
        text-align: left;
    }
    
    @media (max-width: 76px) {
        font-size: unset;
    }
`;