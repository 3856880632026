import React, { useContext } from 'react';
import styled from 'styled-components';
import FormContext from "../../FormContext";
import AnswersIcon from '../../../../../static/answers.svg';

const IconWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: clamp(var(--valFormNavigationWrappers));
  width: clamp(var(--valFormNavigationWrappers));
  padding: 10px; 
  display: grid;
  place-items: center;
  
  svg {
    fill: var(--advSvg);
    width: 30px;
    height: auto;
    cursor: pointer;
    transition: transform .3s ease-in-out;
    
    &:hover {
      //transform: skew(10deg);
      transform: rotate(-15deg);
    }
  }  
`;

const AnswersWrapper = styled.div`
  position: relative;
`;

const Answers = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const CurrentAnswers = () => {
    const { state } = useContext(FormContext);
    const yes = 'yes'
    const no = 'no'

    return (
        <IconWrapper>
            <AnswersIcon/>
            <AnswersWrapper>
                <Answers>
                {
                    state.price === '' &&
                    <span>
                            Czy posiadasz projekt graficzny:
                        {state.priceW1 === '600' ? yes : no}
                        </span>
                }
                {
                    state.price === '' &&
                    <span>
                            Czy posiadasz projekt graficzny:
                        {state.priceW2 === '600' ? yes : no}
                        </span>
                }
                </Answers>
            </AnswersWrapper>
        </IconWrapper>
    );
};

export default CurrentAnswers;