import React from 'react';
import styled from 'styled-components';
import { ValuationFormInnerWrapper } from "../components/ValuationForm/components"
import FormController from "../components/ValuationForm/FormController"
import { graphql } from "gatsby"
import Seo from "../components/seoComponent"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../layout/layout"
import { websiteUrl } from "../data/navItems"

const ValuationFormOuterWrapper = styled.section`
    min-height: calc(100vh - 100px);
    width: 99%;
    display: grid;
    margin: 0 auto 3rem;
    background: var(--black);
    box-shadow: 0 5px 20px -8px var(--black);
    border-radius: 0 0 1rem 1rem;
    
    @media (min-width: 980px) {
        max-height: calc(100vh - 100px);
    }    
    
    .background, .content {
        display: grid;
        grid-area: 1/1;
        border-radius: inherit;
        min-height: inherit;
        max-height: inherit;
        
        .gatsby-image-wrapper {
            border-radius: inherit;
            min-height: inherit;
            max-height: inherit;
            filter: brightness(.4);
        }
    }
`;

const ValuationPage = ({ location }) => {

    const path = location.pathname;
    const url = `${websiteUrl + path}`;

    return (
        <Layout>
            <Seo
                title="Błyskawiczna wycena"
                description="Sprawdź ile kosztuje nowoczesna strona internetowa lub internetowy sklep e-commerce. Umów się na konsultację i uzyskaj ofertę."
                pageUrl={url}
                pageName={path.slice(1,-1)}
                keywords={['strona internetowa cena', 'ile kosztuje strona internetowa', 'strona internetowa wycena', 'jamstack', 'sklep internetowy cena', 'sklep internetowy ile kosztuje']}
            />
            <ValuationFormOuterWrapper className="valuation_form">
                <div className="background">
                    <StaticImage
                        src={'../../static/laptop.jpg'}
                        alt={'Laptop'}
                        // layout="fullWidth"
                        // width="1200"
                        // height="500"
                        aspectRatio={4 / 3}
                        fit={"contain"}
                        formats={["auto", "webp", "avif"]}
                     />
                </div>
                <div className="content">
                    <ValuationFormInnerWrapper>
                        <FormController />
                    </ValuationFormInnerWrapper>
                </div>
                {/*<ValuationFormBackgroundImage fluid={data.valuationFormImage.childImageSharp.fluid}>*/}
                {/*</ValuationFormBackgroundImage>*/}
            </ValuationFormOuterWrapper>
        </Layout>
    )
}

export const query = graphql`
    query {
        valuationFormImage: file(name: {eq: "laptop"}) {
            id
            childImageSharp {
                fluid( maxWidth: 1600, quality: 50) {
                    ...GatsbyImageSharpFluid
                }
                resize(width: 1200) {
                    src
                    height
                    width
                }
            }
        }
    }
`;

export default ValuationPage;