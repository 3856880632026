import React, { useContext, useState } from "react"
import styled from 'styled-components';
import FormContext from "../../FormContext";
import ResetIcon from '../../../../../static/reset2.svg';
import { useTranslation } from "react-i18next"

const IconWrapper = styled.button`
    display: grid;
        place-items: center;
    width: 65px;
    padding: 0; 
    background: none;
    border: none;
    visibility: ${({ hidden }) => hidden && 'hidden'};
    ${props => props.hidden && 'visibility: hidden'};
    
    //@media (max-width: 768px) {
    //    width: 80px;
    //}
    
    svg {
        width: clamp(25px, 2vw, 30px);
        height: auto;
        cursor: pointer;
        transition: transform .3s ease-in-out;
        will-change: transform;
    
    path {  
        fill: var(--white);
        stroke-width: 4%;
        stroke: white;
        transition: fill .3s ease-out, stroke .3s ease-out;
    }
    
    &:hover {
        //transform: skew(10deg);
        transform: rotate(-15deg) scale(1.2);
        
        path {
            //fill: red;
            //stroke: red;
        }
    }
}  
    
`;

const Tooltip = styled.div`
    display: ${({ isTooltipVisible }) => isTooltipVisible ? 'block' : 'none'};
    position: absolute;
        bottom: 0;
        left: 0;
        z-index: 1;
        transform: translateY(100%);       
    border: 1px solid pink;
    background: magenta;
    color: white;
    padding: .75em .5em;
`;

const Reset = ({ hidden, withTooltip }) => {

    const { resetForm } = useContext(FormContext);
    const { t } = useTranslation(['valform']);
    const [isVisible, setIsVisible] = useState(true);

    return (
        <IconWrapper hidden={hidden} title={t('valform:valform_utils.reset_hint')}>
            <ResetIcon onClick={resetForm}/>
            {withTooltip &&
                <Tooltip isTooltipVisible={isVisible} isVisible={isVisible} setIsVisible={setIsVisible}>
                    Ten przycisk usuwa wszystkie zapisane odpowiedzi i przenosi do pierwszego pytania.
                    <span role="button" tabIndex="0" onClick={() => setIsVisible(!isVisible)}>Zakóncz samouczek</span>
                    <span>Następna wskazówka</span>
                </Tooltip>
            }
        </IconWrapper>
    )
};

export default Reset;