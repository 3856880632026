import React from 'react';
import BriefQuestion from "../../Questions/BriefQuestion"
import { briefData } from "../../Common/Brief/Common/data-seed"

export function CompanyName({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.landingPage.what_do_you_do}
        stateKey="companyName"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function SocialMedia({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.landingPage.social_media}
        stateKey="socialMedia"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function TargetGroup({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.target_group.question}
        hint={briefData.common.target_group.hint}
        stateKey="targetGroup"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function UniqueAttributes({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.unique_attributes.question}
        hint={briefData.common.unique_attributes.hint}
        stateKey="uniqueAttributes"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}