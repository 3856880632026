import React, { useContext } from "react"
import FormContext from "../FormContext";
import { NavigationWrapper, Reset, Pagination, BackFirstQuestAndNext, NavigateSteps } from "../components/Navigation/index";
import DialogType from "../components/DialogType"
import ScrollProgressIndicator from "../../ScrollProgressIndicator"

const TypeAnswer = ({ children, firstQuestion, introQuestion, backToWebsiteType, grayhint, currentStep, allSteps }) => {

    const { getAllSteps, state } = useContext(FormContext);

    return (
        <>
            <DialogType grayhint={grayhint} className="animate" state={state}>
                {children}
            </DialogType>
            <NavigationWrapper
                onTop={
                    <ScrollProgressIndicator targetValue={currentStep} maxValue={allSteps} />
                }
            >
                <Reset />
                { (!firstQuestion && !backToWebsiteType && !introQuestion) && <NavigateSteps prevAndNext /> }
                { firstQuestion && <BackFirstQuestAndNext/>  }
                { backToWebsiteType && <BackFirstQuestAndNext product={'website_type'} step={1} /> }
                { introQuestion && <BackFirstQuestAndNext nextStepDisabled /> }
                <Pagination currentStep={currentStep} allSteps={allSteps ? allSteps : getAllSteps()} />
            </NavigationWrapper>
        </>
    );
};

export default TypeAnswer;