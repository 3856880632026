import React, { useContext } from "react"
import FormContext from "../FormContext";
import PriceSummaryContent from "../components/PriceSummaryContent"
import roundOffMoney from "../../../utils/roundOffMoney"

const LandingPagePriceSummary = () => {

    const { state, priceEcommerceFixedCosts, priceEcommerceMultipliers, priceEcommerceCondition } = useContext(FormContext);

    const pricePLN = (priceEcommerceFixedCosts * priceEcommerceMultipliers).toFixed(0);
    state.totalPrice = roundOffMoney(pricePLN);
    state.finalProduct = state.product;

    return (
        <PriceSummaryContent Condition={priceEcommerceCondition} productType={'ecommerce'} />
    )
};

export default LandingPagePriceSummary;