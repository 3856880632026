import React from 'react';
import BriefQuestion from "../../Questions/BriefQuestion"
import { briefData } from "../../Common/Brief/Common/data-seed"

export function CompanyName({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.ecommerce.company_name}
        stateKey="companyName"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function SocialMedia({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.ecommerce.social_media_and_website}
        stateKey="socialMedia"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function UniqueAttributes({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.ecommerce.unique_attributes.question}
        hint={briefData.ecommerce.unique_attributes.hint}
        stateKey="uniqueAttributes"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function EcommerceMap({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.ecommerce.ecommerce_map.question}
        hint={briefData.ecommerce.ecommerce_map.hint}
        stateKey="ecommerceMap"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

