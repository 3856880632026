import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next";
import FormContext from './FormContext';
import { gsap } from 'gsap';
import { InfoStyles } from "./components";
import { ButtonButton } from "./components/Button/ButtonButton"

const Welcome = () => {

    const { t } = useTranslation(['valform']);
    const { nextStep } = useContext(FormContext);

    useEffect(() => {
        gsap.to('#anim', {autoAlpha: 1, duration: 1});
    },[]);

    return (
        <InfoStyles welcome marginTopMinus id="anim">
            <div className="container">
                <h3 className="important_info fontsize120">
                    {t('valform:valform_intro.intro1')}
                </h3>
                <p className="extra_info">
                    {t('valform_intro.intro2')}
                </p>
            </div>
            <ButtonButton alignLeft filled onClick={nextStep}>
                {t('valform:valform_intro.intro_btn')}
            </ButtonButton>
            {/*<NavigationWrapper>*/}
            {/*    <Reset />*/}
            {/*    <DisabledSteps />*/}
            {/*    <Pagination visualHidden />*/}
            {/*</NavigationWrapper>*/}
        </InfoStyles>
)};

export default Welcome;