import React, { useEffect } from "react"
import styled from 'styled-components';
import { gsap } from 'gsap';

const StyledIndicator = styled.progress`

    position: absolute;
        top: 0;
        left: 0;
        z-index: 3;
        transform: translateY(-100%);
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 4px;
    border: none;
    background: transparent;
    
    @media (max-width: 768px) {
        top: 60px;
        height: 2px;
    }
    
    &::-webkit-progress-bar {
        background: transparent;
    }
    --bg: var(--white);
    &::-webkit-progress-value {
        background: var(--bg);
        background-attachment: fixed;
    }
    &::-moz-progress-bar {
        background: var(--bg);
        background-attachment: fixed;
    }
`;

export default function ScrollProgressIndicator({ targetValue, maxValue }) {

    useEffect(() => {
        gsap.to('progress',
            // { value: targetValue - 1 },
            { value: targetValue, ease: 'none', duration: .5, });
    })

    return (
        <StyledIndicator max={maxValue} value={targetValue - 1} />
    )
}
// scrollTrigger: {
//     trigger: element,
//     start: '0 0',
//     end: '100% 50%',
//     scrub: 0.3,
// },
