export const answersProductsCount = [
    {
        answer: 'valform_ecommerce.1-10_products',
        answerId: '1-10_products',
        price: 1,
    },
    {
        answer: 'valform_ecommerce.10-50_products',
        answerId: '10-50_products',
        price: 1.25,
    },
    {
        answer: 'valform_ecommerce.50-200_products',
        answerId: '50-200_products',
        price: 1.5,
    },
    {
        answer: 'valform_ecommerce.200-1000_products',
        answerId: '200-1000_products',
        price: 2,
    },
]

export const answersControlProductsDetails = [
    {
        answer: 'valform_ecommerce.yes_i_wanna_change_it',
        answerId: 'control-product-details-yes',
        price: 800,
    },
    {
        answer: 'valform_ecommerce.no_i_wanna_set_it_once',
        answerId: 'control-product-details-no',
        price: 200,
    },
]

export const answersAccountRegistration = [
    {
        answer: 'valform_utils.yes',
        answerId: 'account-registration-yes',
        price: 1000,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'account-registration-no',
        price: 500,
    },
]

export const answersDiscounts = [
    {
        answer: 'valform_utils.yes',
        answerId: 'discounts-yes',
        price: 1000,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'discounts-no',
        price: 500,
    },
]

export const answersStockAvailability = [
    {
        answer: 'valform_ecommerce.yes_automated',
        answerId: 'stock-availability-yes-automated',
        price: 1000,
    },
    {
        answer: 'valform_ecommerce.yes_manually',
        answerId: 'stock-availability-yes-manually',
        price: 500,
    },
    {
        answer: 'valform_ecommerce.no_not_at_all',
        answerId: 'stock-availability-no',
        price: 200,
    },
]

export const answersLabelProducts = [
    {
        answer: 'valform_utils.yes',
        answerId: 'label-products-yes',
        price: 800,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'label-products-no',
        price: 400,
    },
]

export const answersIntegration = [
    {
        answer: 'valform_utils.yes',
        answerId: 'integrations-yes',
        price: 1500,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'integrations-no',
        price: 500,
    },
]

export const answersVariants = [
    {
        answer: 'valform_ecommerce.yes_many',
        answerId: 'variants-yes-many',
        price: 1500,
    },
    {
        answer: 'valform_ecommerce.yes_few',
        answerId: 'variants-yes-few',
        price: 1000,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'variants-no',
        price: 500,
    },
]

export const answersSearchBar = [
    {
        answer: 'valform_utils.yes',
        answerId: 'variants-yes-many',
        price: 1200,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'variants-no',
        price: 400,
    },
]

export const eCommerceData = {
    answersProductsCount,
    answersControlProductsDetails,
    answersAccountRegistration,
    answersDiscounts,
    answersStockAvailability,
    answersLabelProducts,
    answersIntegration,
    answersVariants,
    answersSearchBar

}