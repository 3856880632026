import React, { useEffect } from "react"
import styled from 'styled-components';
import { gsap } from 'gsap';

const StyledDialog = styled.dialog`
    text-align: center;
    max-width: 1024px;
    width: 100%;
    position: static;
    height: unset;
    color: var(--white);
    background: unset;
    border: none;
    padding: 0;
    margin: 0 auto;
        opacity: 0;
        visibility: hidden;    
    
    
    //@media (max-width: 768px) {
    //    padding: 0 7px;
    //}
    
    p.question {
        color: var(--white);
        font-weight: bold;
        font-size: 120%;
        margin: 0 auto 2rem;
        padding: 0 .5em;
    
        span {
            font-weight: bold;
        }
    }  
    
    .text {
        width: 100%;
    }
    
    h3 {
        font-size: 170%;
        margin-top: 30px;
        margin-bottom: 20px;
        
        @media (max-width: 768px) {
            font-size: 130%;
        }
    }
    
    h1 {
        margin-bottom: 5px;
    }
    
    h3, h1 {
        color: white;
    }
    
    h4 {
        color: #d7d7d7;
        margin-bottom: 10px;
    }
    
    ul, ol {
        list-style: none;
        margin: 0;
    
        li {
          display: flex;
          flex-direction: column;
          margin-bottom: 0;
        }
    }
    
    button {
        position: relative;
        border: none;
        cursor: pointer;
        background: transparent;
        padding: 2px 12px;
        color: white;
        border-bottom: 2px solid #1589BA;
        overflow: hidden;
        z-index: 1;
        transition: border-bottom-color .3s ease-in-out!important;
        
        &:before {
            position: absolute;
            content: "";
            z-index: -1;
            background-color: #1589BA;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: translateY(100%);
            transition: transform .3s ease, background-color .3s ease-in-out;
        }
        
        &:hover:before {
            transform: translateY(0);
            background-color: white;
        }
        
        &:hover {
            color: #1589BA;
            border-bottom-color: white;
}
    }
`;

const Dialog = ({ children }) => {

    // useEffect(() => {
    //     const Q = gsap.utils.toArray('.form_question');
    //     gsap.set(Q, { duration: 2, ease: 'power4.out' })
    //     gsap.to(Q, { opacity: 0 });
    //
    //     Q.forEach((el, index) => {
    //         gsap.fromTo('.form_question', { x: -75, autoAlpha: 0 }, { x: 0, autoAlpha: 1 });
    //     });
    //
    //     return () => {
    //         Q.forEach((el, index) => {
    //             gsap.to('.form_question', { x: -75, autoAlpha: 0 });
    //         });
    //     }
    // }, []);

    useEffect(() => {
        gsap.fromTo('.form_question',
            { x: -50, autoAlpha: 0 },
            { x: 0, autoAlpha: 1, duration: .5 }
        );
    }, []);

    useEffect(() => {
        let input = document.querySelectorAll('.input_answer');
        document.addEventListener('keydown', function (event) {
            if (event.key === 'a') {
                input[0]?.click();
            }
            if (event.key === 'b') {
                input[1]?.click();
            }
            if (event.key === 'c') {
                input[2]?.click();
            }
            if (event.key === 'd') {
                input[3]?.click();
            }
            if (event.key === 'e') {
                input[4]?.click();
            }
            if (event.key === 'f') {
                input[5]?.click();
            }
            if (event.key === 'g') {
                input[6]?.click();
            }
            if (event.key === 'h') {
                input[7]?.click();
            }
        });
    })

    return (
        <StyledDialog open className="form_question animate">
            {children}
        </StyledDialog>
    )
};

export default Dialog;
