import styled from 'styled-components';
import Arrow from '../../../static/right-arrow.svg'

export const RightArrow = styled(Arrow)`
    display: flex;
    width: clamp(25px,2vw,30px);
    //margin-left: 3px;
    fill: var(--white);
    background: var(--formInputBorder);
    transition: transform .3s ease-out;
    will-change: transform;
    border-radius: 5px;
    
    path {
        fill: var(--white);
    }
    
    &:hover {
        transform: scale(1.2);
    }
`;