import React, { useContext, useEffect } from "react"
import FormContext from "../FormContext"
import { useTranslation } from "react-i18next"
import { gsap } from "gsap"
import { InfoStyles } from "./styles/InfoStyles"
import { ImportantInfoStyles } from "./index"
import { ButtonButton } from "./Button/ButtonButton"
import { NavigationWrapper, Pagination, NavigateSteps, Reset } from "./Navigation";


const PriceSummaryContent = ({ Condition, productType }) => {

    useEffect(() => {
        gsap.to('#anim', { autoAlpha: 1, duration: 1});
    }, []);

    const { t, i18n } = useTranslation(['valform']);
    const locale = i18n.language;
    const { state, nextStep, missingAnswers, dollarPrice } = useContext(FormContext);
    const pricePLN = state.totalPrice;
    const priceUSD = (state.totalPrice / dollarPrice).toFixed();

    return (
        <>
            <InfoStyles alignLeft id="anim">
                {!Condition &&
                <>
                    <div className="estimated_price">
                        <p>
                            {t('valform:valform_summary.wSummary1')}:
                            <br/>
                        </p>
                        {locale === 'pl' &&
                            <span className="price">{pricePLN}{' '}zł</span>
                        }
                        {locale === 'en' &&
                            <span className="price">{priceUSD}{' '}$</span>
                        }
                    </div>
                    {/*<p>{t('valform:valform_summary.wSummary2')}</p>*/}
                    <ImportantInfoStyles marginTop alignLeft>
                        {t('valform:valform_summary.would_like_to_cooperate')}
                    </ImportantInfoStyles>
                    {/*<div className="buttons_wrapper">*/}
                        {/*<PureButton filled type="button" tabindex="0" onClick={prevStep}>*/}
                        {/*    {t('valform:valform_utils.return')}*/}
                        {/*</PureButton>*/}
                    {/*</div>*/}
                    <ButtonButton alignLeft marginTop onClick={nextStep}>
                        {t('valform:valform_utils.next')}
                    </ButtonButton>
                </>
                }
                {Condition &&
                <>
                    <p className="important_info">
                        {t('valform:valform_summary.not_answered')}
                    </p>
                    <p className="extra_info">
                        {t('valform:valform_summary.plz_answer')}
                    </p>
                        <ButtonButton alignLeft marginTop onClick={()=>missingAnswers(productType)}>
                            {t('valform:valform_utils.return')}
                        </ButtonButton>
                    {/*<div className="buttons_wrapper">*/}
                    {/*</div>*/}
                </>
                }
            </InfoStyles>
            <NavigationWrapper>
                <Reset />
                <NavigateSteps prevAndNext disableNextStep />
                <Pagination visualHidden />
            </NavigationWrapper>
        </>
    )

}

export default PriceSummaryContent;