import React, { useContext, useEffect } from "react"
import FormContext from "./FormContext"
import { useTranslation } from "react-i18next"
import { gsap } from "gsap"
import Link from 'gatsby-link'
import { InfoStyles, TextInputStyles } from "./components"
import { ButtonButton } from "./components/Button/ButtonButton"
import { NavigateSteps, NavigationWrapper, Pagination, Reset } from "./components/Navigation"

export default function BriefIntro() {
    const { nextStep } = useContext(FormContext);
    const { t, i18n } = useTranslation(['valform']);
    const locale = i18n.language;

    useEffect(() => {
        gsap.to('.animate', { autoAlpha: 1, duration: 1});
    }, []);

    return (
        <>
            <InfoStyles hidden className="animate">
                {/*<ScheduleConsultation*/}
                {/*    url={'https://calendly.com/panodstrony/konsultacja-projektu'}*/}
                {/*    minWidth={400}*/}
                {/*    height={500}*/}
                {/*/>*/}
                <p>{t('valform:valform_summary.brief_intro_1')}</p>
                <p>{t('valform:valform_summary.brief_intro_2')}</p>
                <p>
                    {t('valform:valform_summary.brief_intro_3')}
                    {' '}
                    <Link to="/kontakt">
                        {locale === 'pl' && 'kontakt'}
                        {locale === 'en' && 'contact'}
                    </Link>
                    {'.'}
                </p>
                <TextInputStyles marginTop noPadding>
                    <ButtonButton send onClick={nextStep}>
                        {t('valform:valform_utils.next')}
                    </ButtonButton>
                </TextInputStyles>
            </InfoStyles>
            <NavigationWrapper>
                <Reset />
                <NavigateSteps prevAndNext disableNextStep />
                <Pagination visualHidden />
            </NavigationWrapper>
        </>
    )
}