import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FormContext from "./FormContext";
import { NavigationWrapper, Pagination, Reset, NavigateSteps } from "./components/Navigation";
import { AdditionalInfo, Dialog, RadioInputStyles } from "./components/";

const WhatProduct = () => {

    const { t } = useTranslation(['valform']);
    const { chooseProduct, getDollarPrice } = useContext(FormContext);

    useEffect(() => {
        getDollarPrice();
    });

    return (
        <>
        <Dialog>
            <p className="question">
                {t('valform:valform_intro.question1')}
            </p>
            <RadioInputStyles wide>
                <button
                    className="input_answer"
                    tabIndex="0" role="button"
                    type="radio"
                    id="website"
                    name="whatproduct"
                    onClick={() => chooseProduct('website_type')}
                    value={500}
                    aria-label={t('valform:valform_intro.website')}
                />
                <label htmlFor="website">
                    {t('valform:valform_intro.website')}
                </label>
            </RadioInputStyles>
            <RadioInputStyles wide>
                <button
                    className="input_answer"
                    type="radio"
                    id="ecommerce"
                    name="whatproduct"
                    placeholder="e-commerce"
                    onClick={() => chooseProduct('ecommerce')}
                    value={1000}
                    aria-label={t('valform:valform_intro.ecommerce')}
                />
                <label htmlFor="ecommerce">
                    {t('valform:valform_intro.ecommerce')}
                </label>
            </RadioInputStyles>
            <AdditionalInfo showOnlyOnDesktop>
                {t('valform:valform_intro.keyboard_navigate_tip')}
            </AdditionalInfo>
        </Dialog>
        <NavigationWrapper>
            <Reset />
            <NavigateSteps disabledSteps />
            <Pagination currentStep={1} allSteps={1} />
        </NavigationWrapper>
        </>
    )
};

export default WhatProduct;