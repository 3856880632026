export const answersGoal = [
    {
        answer: 'valform_website.subcribe_to_newsletter',
        answerId: 'newsletter_sub',
        price: 300,
    },
    {
        answer: 'valform_website.webinar_registration',
        answerId: 'webinar_register',
        price: 200,
    },
    {
        answer: 'valform_website.schedule_consultation',
        answerId: 'schedule_consultation',
        price: 300,
    },
    {
        answer: 'valform_website.buy_product',
        answerId: 'buy_product',
        price: 400,
    },
    {
        answer: 'valform_website.ebook_download',
        answerId: 'ebook_download',
        price: 400,
    },
    {
        answer: 'valform_website.fill_the_form',
        answerId: 'form_submission',
        price: 300,
    },
    {
        answer: 'valform_website.interest_study',
        answerId: 'interest_study',
        price: 300,
    },
]

export function mapPriceWithGoal(state) {
    switch (state.priceLandingPageGoal) {
        case 'newsletter_sub':
            return 600
        case 'webinar_register':
            return 500
        case 'schedule_consultation':
            return 600
        case 'buy_product':
            return 800
        case 'ebook_download':
            return 500
        case 'form_submission':
            return 500
        case 'interest_study':
            return 800
        default:
            return 0
    }
}

export const answersCMS = [
    {
        answer: 'valform_utils.yes',
        answerId: 'cms-yes',
        price: 1.5,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'cms-no',
        price: 1,
    },
];

export const answersProject = [
    {
        answer: "valform_utils.yes",
        answerId: "project-yes",
        price: 1,
    },
    {
        answer: "valform_utils.no",
        answerId: "project-no",
        price: 1.5,
    }
];

export const landingPageData = {
    answersGoal,
    answersCMS,
    answersProject
}