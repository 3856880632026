import React, { useContext } from "react"
import FormContext from '../FormContext';
import { EcommercePriceSummary, Name, Mail, EcommerceOverall, BriefIntro } from "./../index"
import { AccountRegistration, ControlProductsDetails, Discounts, Integration, LabelProducts, ProductsCount, SearchBar, StockAvailability, Variants } from "./Valuation/questions"
import { CompanyName, EcommerceMap, SocialMedia, UniqueAttributes } from "./Brief/questions"
import { AnythingElse, BrandResources, ConcurrencyWebsites, DesignTastes } from "../Common/Brief/Common/questions"

const EcommerceController = () => {

    const { state, windowGlobal } = useContext(FormContext);
    const currentProduct = windowGlobal.localStorage.getItem('product');
    if (currentProduct) {
        state.product = currentProduct;
    }
    const allSteps = 10;

    switch(state.step) {
        default:
            return (
                <ProductsCount />
            )

        case 2:
            return (
                <ControlProductsDetails />
            );

        case 3:
            return (
                <AccountRegistration />
            );

        case 4:
            return (
                <Discounts />
            );

        case 5:
            return (
                <StockAvailability />
            );

        case 6:
            return (
                <LabelProducts />
            );

        case 7:
            return (
                <Integration />
            );

        case 8:
            return (
                <Variants />
            );

        case 9:
            return (
                <SearchBar />
            );

        case 10:
            return (
                <EcommercePriceSummary />
            );

        case 11:
            return (
                <BriefIntro />
            )

        case 12:
            return (
                <Name currentStep={1} allSteps={allSteps} />
            );


        case 13:
            return (
                <Mail currentStep={2} allSteps={allSteps} />
            );

        case 14:
            return (
                <CompanyName currentStep={3} allSteps={allSteps} />
            );

        case 15:
            return (
                <SocialMedia currentStep={4} allSteps={allSteps} />
            );

        case 16:
            return (
                <UniqueAttributes currentStep={5} allSteps={allSteps} />
            );

        case 17:
            return (
                <ConcurrencyWebsites currentStep={6} allSteps={allSteps} />
            );

        case 18:
            return (
                <DesignTastes currentStep={7} allSteps={allSteps} />
            );

        case 19:
            return (
                <BrandResources currentStep={8} allSteps={allSteps} />
            );

        case 20:
            return (
                <EcommerceMap currentStep={9} allSteps={allSteps} />
            );

        case 21:
            return (
                <AnythingElse currentStep={10} allSteps={allSteps} />
            );

        case 22:
            return (
                <EcommerceOverall functionName={'processEcommerceValuation'}/>
            );

    }
};
export default EcommerceController;