import React, { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next";
import FormContext from "../FormContext";
import { useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import DialogType from "../components/DialogType";
import { TextInputStyles } from '../components';
import ButtonTip from "../components/ButtonTip";
import { ButtonButton } from "../components/Button/ButtonButton"
import Error from '../../../../static/error.svg';
import AdditionalInfo from "../components/AdditionalInfo"
import { NavigationWrapper, Pagination, Reset } from "../components/Navigation/index"
import ScrollProgressIndicator from "../../ScrollProgressIndicator"
import NavigateSteps from "../components/Navigation/NavigateSteps"

const Name = ({ introQuestion, currentStep, allSteps }) => {

    const { t } = useTranslation(['valform']);
    const { state, windowGlobal, nextStep, setState, getAllSteps } = useContext(FormContext);
    const { register, handleSubmit, formState: { errors } } = useForm();

    const cache = windowGlobal.localStorage.getItem('name');
    useEffect(() => {
        if(cache) setState({...state, name: cache})
    }, []);

    const onSubmit = data => {
        if (data) {
            windowGlobal.localStorage.setItem('name', data.name);
            setState({...state, name: data.name});
            nextStep();
        }
    };

    const nameField = register('name', {
        required: t('valform:valform_utils.missing_field'),
        maxLength: {
            value: 60,
            message: `${t('valform:contact_form.max_length')} 60`
        }
    });

    return (
        <>
            <DialogType introQuestion={introQuestion}>
                <TextInputStyles>
                    <label htmlFor="name">
                        {t('valform:valform_personal_data.name')}
                    </label>
                    <input
                        type="text"
                        placeholder={t('valform:valform_utils.type_here')}
                        className={ errors.name && 'shake' }
                        id="name"
                        name="name"
                        defaultValue={state.name || cache}
                        onChange={(e) => {
                            nameField.onChange(e);
                            setState({
                                ...state,
                                name: e.target.value
                            })
                            windowGlobal.localStorage.setItem('name', e.target.value);
                         }}
                        ref={nameField.ref}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="name"
                        render={({message}) => <small><Error/> {message}</small>}
                    />
                </TextInputStyles>
                <TextInputStyles marginTop>
                    <ButtonButton alignLeft send onClick={handleSubmit(onSubmit)}>
                        {t('valform:valform_utils.next')}
                    </ButtonButton>
                    <ButtonTip visible={state.name}/>
                </TextInputStyles>
                <AdditionalInfo>{t('valform:valform_utils.answers_are_saved_hint')}</AdditionalInfo>
            </DialogType>
            <NavigationWrapper
                onTop={
                    <ScrollProgressIndicator targetValue={currentStep} maxValue={allSteps} />
                }
            >
                <Reset/>
                <NavigateSteps prevAndSubmit disabledProps={errors.name || state.name === ''} submitAnswer={handleSubmit(onSubmit)} />
                {/*{introQuestion ?*/}
                {/*    <NoBackStepAndSubmitStep submitAnswer={handleSubmit(onSubmit)} />*/}
                {/*    :*/}
                {/*}*/}
                {/*<Reset hidden/>*/}
                <Pagination currentStep={currentStep} allSteps={allSteps ? allSteps : getAllSteps()} />
            </NavigationWrapper>
        </>
    );
};


export default Name;