import styled from 'styled-components';

export const ValuationFormInnerWrapper = styled.section`
    display: flex;
        align-items: center;
        justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: inherit;
    touch-action: manipulation;
    
    @media (max-width: 768px) {
        padding: 5rem 0 0;
        align-items: start;
        font-size: small;
        
        input, textarea {
            font-size: initial;
        }
    }
    
`;
