import React, { useContext } from "react";
import FormContext from '../FormContext';
import { WebsitePriceSummary, Name, Mail, WebsiteOverall, BriefIntro } from "./../index"
import {
    Pages,
    Form,
    Consultation,
    Blog,
    Newsletter,
    Chat,
    Languages,
    Cms,
    DarkMode,
    Project,
    Animations
} from "./Valuation/questions"
import { CompanyName, SocialMedia, Purpose, TargetGroup, ClientsProblems, UniqueAttributes, WebsiteMap, } from "./Brief/questions"
import { AnythingElse, BrandResources, DesignTastes, ConcurrencyWebsites } from "../Common/Brief/Common/questions"

const WebsiteController = () => {

    const { state, windowGlobal } = useContext(FormContext);
    const currentProduct = windowGlobal.localStorage.getItem('product');
    if (currentProduct) {
        state.product = currentProduct;
    }
    const allSteps = 13;

    switch(state.step) {

        default:
            return (
                <Pages />
            );

        case 2:
            return (
                <Form />
            );

        case 3:
            return (
                <Blog />
            );

        case 4:
            return (
                <Consultation />
            );

        case 5:
            return (
                <Newsletter />
            );

        case 6:
            return (
                <Chat />
            );

        case 7:
            return (
                <Languages />
            );

        case 8:
            return (
                <Cms />
            );

        case 9:
            return (
                <DarkMode />
            );

        case 10:
            return (
                <Animations />
            )

        case 11:
            return (
                <Project />
            );

        case 12:
            return (
                <WebsitePriceSummary />
            );

        case 13:
            return (
                <BriefIntro />
            )

        case 14:
            return (
                <Name currentStep={1} allSteps={allSteps} />
            );


        case 15:
            return (
                <Mail currentStep={2} allSteps={allSteps} />
            );

        case 16:
            return (
                <CompanyName currentStep={3} allSteps={allSteps} />
            );

        case 17:
            return (
                <SocialMedia currentStep={4} allSteps={allSteps} />
            );

        case 18:
            return (
                <Purpose currentStep={5} allSteps={allSteps} />
            );

        case 19:
            return (
                <TargetGroup currentStep={6} allSteps={allSteps} />
            );

        case 20:
            return (
                <ClientsProblems currentStep={7} allSteps={allSteps} />
            );

        case 21:
            return (
                <UniqueAttributes currentStep={8} allSteps={allSteps} />
            );

        case 22:
            return (
                <ConcurrencyWebsites currentStep={9} allSteps={allSteps} />
            );

        case 23:
            return (
                <DesignTastes currentStep={10} allSteps={allSteps} />
            );

        case 24:
            return (
                <BrandResources currentStep={11} allSteps={allSteps} />
            );

        case 25:
            return (
                <WebsiteMap currentStep={12} allSteps={allSteps} />
            );

        case 26:
            return (
                <AnythingElse currentStep={13} allSteps={allSteps} />
            );

        case 27:
            return (
                <WebsiteOverall functionName={'processWebsiteValuation'} />
            );

    }
};
export default WebsiteController;