import styled from 'styled-components';
import React, {useContext} from "react";
import FormContext from "../../FormContext";

const PaginationStyles = styled.div`
    //position: absolute;
    color: white;
    font-size: 120%;
    right: 0;
    bottom: 0;
    padding: 5px;
    --size: 60px;
    height: 60px;
    width: 65px;
    text-align: center;
    visibility: ${({ visualHidden }) => visualHidden && 'hidden'};
    
    //@media (max-width: 768px) {
    //    width: 80px;
    //    font-size: unset;
    //}
`;

const StepsWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    
    &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(45deg);
        width: 4px;
        height: 70%;
        background-color: var(--black);
    }
    
    span {
        position: absolute;
        line-height: normal;
        width: 2ch;
        text-align: center;
        font-weight: bold;
        color: var(--white);
    }
    
    span:nth-child(1) {
        top: 0;
        left: 0;
    }
    span:nth-child(2) {
        bottom: 0;
        right: 0; 
    }
`;



const Pagination = ({ currentStep, allSteps, visualHidden }) => {

    const { state } = useContext(FormContext);

    return (
    <PaginationStyles visualHidden={visualHidden}>
        <StepsWrapper>
            <span>
                {currentStep ? currentStep : state.step}
            </span>
            <span>
                {allSteps}
            </span>
        </StepsWrapper>
    </PaginationStyles>
)};

export default Pagination