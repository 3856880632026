import React, {useContext} from "react";
import {LeftArrow, RightArrow} from "../../../SVG";
import {ButtonWrapper} from "./ButtonWrapper";
import FormContext from "../../FormContext";
import { useTranslation } from "react-i18next";

function ButtonLeft({ handleClick, disabled }) {
    const { t } = useTranslation(['valform']);
    return (
        <button disabled={disabled} onClick={handleClick} title={t('valform:valform_utils.prev_step_hint')}>
            <LeftArrow />
        </button>
    )
}

function ButtonRight({ handleClick, disabled }) {
    const { t } = useTranslation(['valform']);
    return (
        <button disabled={disabled} onClick={handleClick} title={t('valform:valform_utils.next_step_hint')}>
            <RightArrow />
        </button>
    )
}

export default function NavigateSteps({ backFirstQuestionAndNextDisabled, backFirstQuestionAndNext, backToWebsiteType, backToWhatProduct, disabledSteps, noPrevAndSubmit, prevAndNext, prevAndSubmit, disabledProp, submitAnswer, disableNextStep }) {

    const { backToFirstQuestion, prevStep, nextStep } = useContext(FormContext);

    return (
        <ButtonWrapper>
            {backFirstQuestionAndNextDisabled && <>
                <ButtonLeft handleClick={() => backToFirstQuestion()} />
                <ButtonRight disabled />
            </>}
            {backFirstQuestionAndNext && <>
                <ButtonLeft handleClick={() => backToFirstQuestion()} />
                <ButtonRight handleClick={nextStep} />
            </>}
            {backToWebsiteType && <>
                <ButtonLeft handleClick={() => backToFirstQuestion('website_type', 1)} />
                <ButtonRight handleClick={nextStep} />
            </>}
            {backToWhatProduct && <>
                <ButtonLeft handleClick={() => backToFirstQuestion()} />
                <ButtonRight disabled handleClick={nextStep} />
            </>}
            {prevAndNext && <>
                <ButtonLeft handleClick={prevStep} />
                <ButtonRight handleClick={nextStep} disabled={disableNextStep} />
            </>}
            {prevAndSubmit && <>
                <ButtonLeft handleClick={prevStep} />
                <ButtonRight disabled={disabledProp} handleClick={submitAnswer} />
            </>}
            {disabledSteps && <>
                <ButtonLeft disabled />
                <ButtonRight disabled />
            </>}
        </ButtonWrapper>
    )};
