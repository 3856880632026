import React, { useContext } from "react"
import { useTranslation } from "react-i18next";
import FormContext from "../FormContext";
import ChooseAnswer from "./ChooseAnswer";
import { RadioInputStyles } from "../components";

export default function ValuationQuestionString({ question, answers, stateKey, backToWebsiteType }) {

    const { t } = useTranslation(['valform']);
    const { state, windowGlobal, handleChangeString, getAllSteps } = useContext(FormContext);
    const targetValue = getAllSteps('landing_page');

    const cache = windowGlobal.localStorage.getItem(stateKey);
    if (cache !== null) state[stateKey] = cache;

    return (
        <ChooseAnswer backToWebsiteType={backToWebsiteType} targetValue={state.step} maxValue={targetValue}>
            <p className="question">{t(`valform:${question}`)}</p>
            {answers.map(answer => {
                return (
                    <RadioInputStyles
                        wide
                        key={answer.answerId}
                        selected={cache === answer.answerId || state[stateKey] === answer.answerId}
                    >
                        <input
                            className="input_answer"
                            type="radio"
                            id={answer.answerId}
                            name={stateKey}
                            onChange={handleChangeString(stateKey)}
                            value={answer.answerId}
                        />
                        <label htmlFor={answer.answerId}>
                            {t(answer.answer)}
                        </label>
                    </RadioInputStyles>
                )})}
        </ChooseAnswer>
    )
};