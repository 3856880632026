import React, { useContext } from "react"
import FormContext from '../FormContext';
import { Name, Mail, LandingPagePriceSummary, LandingPageOverall, BriefIntro } from "./../index"
import { DesignTastes, BrandResources, AnythingElse } from "../Common/Brief/Common/questions"
import { CompanyName, SocialMedia, TargetGroup, UniqueAttributes } from "./Brief/questions"
import { Cms, Goal, Project } from "./Valuation/questions"

const Controller = () => {

    const { state, windowGlobal } = useContext(FormContext);
    const currentProduct = windowGlobal.localStorage.getItem('product');
    if (currentProduct) { state.product = currentProduct }

    switch(state.step) {
        default:
            return (
                <Goal />
            );

        case 2:
            return (
                <Cms />
            );

        case 3:
            return (
                <Project />
            );

        case 4:
            return (
                <LandingPagePriceSummary/>
            );

        case 5:
            return (
                <BriefIntro />
            )

        case 6:
            return (
                <Name currentStep={1} allSteps={9} />
            );

        case 7:
            return (
                <Mail currentStep={2} allSteps={9} />
            );

        case 8:
            return (
                <CompanyName currentStep={3} allSteps={9} />
            );

        case 9:
            return (
                <SocialMedia currentStep={4} allSteps={9} />
            );

        case 10:
            return (
                <TargetGroup currentStep={5} allSteps={9} />
            );

        case 11:
            return (
                <UniqueAttributes currentStep={6} allSteps={9} />
            );

        case 12:
            return (
                <DesignTastes currentStep={7} allSteps={9} />
            );

        case 13:
            return (
                <BrandResources currentStep={8} allSteps={9} />
            );

        case 14:
            return (
                <AnythingElse currentStep={9} allSteps={9} />
            );

        case 15:
            return (
                <LandingPageOverall functionName={'processLandingPageValuation'}/>
            );
    }
};
export default Controller;