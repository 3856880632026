export const answersPages = [
    {
        answer: 'valform_website.subpages_2',
        answerId: 'subpages-2-5',
        price: 1000,
    },
    {
        answer: 'valform_website.subpages_3',
        answerId: 'subpages-6-10',
        price: 1500,
    },
    {
        answer: 'valform_website.subpages_4',
        answerId: 'subpages-10+',
        price: 2000,
    },
];

export const answersConsultation = [
    {
        answer: 'valform_utils.yes',
        answerId: 'consultation-yes',
        price: 400,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'consultation-no',
        price: 0,
    },
]

export const answersForm = [
    {
        answer: 'valform_utils.yes',
        answerId: 'form-yes',
        price: 200,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'form-no',
        price: 0,
    },
];

export const answersBlog = [
    {
        answer: 'valform_utils.yes',
        answerId: 'blog-yes',
        price: 500,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'blog-no',
        price: 0,
    },
];

export const answersNewsletter = [
    {
        answer: 'valform_utils.yes',
        answerId: 'newsletter-yes',
        price: 200,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'newsletter-no',
        price: 0,
    },
];

export const answersChat = [
    {
        answer: 'valform_utils.yes',
        answerId: 'chat-yes',
        price: 400,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'chat-no',
        price: 0,
    },
];

export const answersLanguages = [
    {
        answer: '1',
        answerId: 'langs-1',
        price: 1,
    },
    {
        answer: '2',
        answerId: 'langs-2',
        price: 1.25,
    },
    {
        answer: '3',
        answerId: 'langs-3',
        price: 1.35,
    },
    {
        answer: '4',
        answerId: 'langs-4',
        price: 1.45,
    },
];

export const answersCMS = [
    {
        answer: 'valform_utils.yes',
        answerId: 'cms-yes',
        price: 1.15,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'cms-no',
        price: 1,
    },
];

export const answersDarkMode = [
    {
        answer: 'valform_utils.yes',
        answerId: 'darkmode-yes',
        price: 1.1,
    },
    {
        answer: 'valform_utils.no',
        answerId: 'darkmode-no',
        price: 1,
    },
];

export const answersAnimations = [
    {
        answer: "valform_common.animations_advanced",
        answerId: "animations-advanced",
        price: 1.7
    },
    {
        answer: "valform_common.animations_normal",
        answerId: "animations-average",
        price: 1.35
    },
    {
        answer: "valform_utils.no",
        answerId: "no-animations",
        price: 1
    },
]

export const answersProject = [
    {
        answer: "valform_utils.yes",
        answerId: "project-yes",
        price: 1,
    },
    {
        answer: "valform_utils.no",
        answerId: "project-no",
        price: 1.25,
    }
];

export const websiteData = {
    answersPages,
    answersConsultation,
    answersForm,
    answersBlog,
    answersNewsletter,
    answersChat,
    answersLanguages,
    answersCMS,
    answersDarkMode,
    answersAnimations,
    answersProject,
}