import styled from "styled-components"
import { ButtonStyles } from "./ButtonStyles"
import Link from 'gatsby-link';

export const ButtonLink = styled(Link)`
    ${ButtonStyles};
    text-decoration: none!important;
    color: var(--white)!important;
    
    &:hover {
        color: var(--black)!important;
    }
`;
