export const briefData = {
    "test_value": "no i jest git",
    steps: 2 + 11,
    common: {
        "brand_resources": {
            question: 'brief.common.brand_resources.question',
            hint: 'brief.common.brand_resources.hint'
        },
        "design_tastes": {
            question: 'brief.common.design_tastes.question',
            hint: 'brief.common.design_tastes.hint'
        },
        "target_group": {
            question: 'brief.common.target_group.question',
            hint: 'brief.common.target_group.hint'
        },
        "unique_attributes": {
            question: 'brief.common.unique_attributes.question',
            hint: 'brief.common.unique_attributes.hint'
        },
        "concurrency_websites": {
            question: "brief.common.concurrency_websites.question",
            hint: "brief.common.concurrency_websites.hint"
        },
        anythingElse: "brief.common.anything_else"
    },
    landingPage: {
        "what_do_you_do": "brief.landing_page.what_do_you_do",
        "social_media": "brief.landing_page.social_media",
    },
    website: {
        "company_name": "brief.website.what_do_you_do",
        "social_media_and_website": "brief.website.social_media_and_website",
        "purpose": {
            question: "brief.website.purpose.question",
            hint: "brief.website.purpose.hint"
        },
        "clients_problems": {
            question: "brief.website.clients_problems.question",
            hint: "brief.website.clients_problems.hint"
        },
        "website_map": {
            question: "brief.website.website_map.question",
            hint: "brief.website.website_map.hint"
        },
    },
    ecommerce: {
        "company_name": "brief.ecommerce.what_do_you_do",
        "social_media_and_website": "brief.ecommerce.social_media_and_website",
        "unique_attributes": {
            question: "brief.ecommerce.unique_attributes.question",
            hint: "brief.ecommerce.unique_attributes.hint",
        },
        "ecommerce_map": {
            question: "brief.ecommerce.ecommerce_map.question",
            hint: "brief.ecommerce.ecommerce_map.hint",
        },
    }
};

