import React, { useContext, useEffect } from "react"
import styled, { keyframes } from 'styled-components';
import { gsap } from 'gsap';
import FormContext from "../FormContext"

const showUp = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const DialogTypeStyled = styled.dialog`
    display: flex;
        flex-direction: column;
        align-items: center;
    text-align: center;
    color: var(--white);
    max-width: 1024px;
    width: 100%;
    position: static;
    height: unset;
    background: unset;
    border: none;
    margin: 0 auto;
    padding: 0 0 3rem;
    opacity: 0;
    
    small.hint {
        display: block;
        
        a {
            color: var(--gray);
            text-decoration: var(--primary) underline solid 1px;
            text-decoration-skip-ink: none;
        }
    }
    
    small {
        position: ${({ grayhint }) => grayhint ? 'none' : 'absolute'};
        top: 100%;
        color: ${({ grayhint }) => grayhint ? 'var(--gray)' : 'var(--red)'};
        display: flex;
        align-items: center;
        padding-top: 2px;
        transition: .3s ease-in-out;
        font-weight: normal;
        animation: ${showUp} .3s ease-out;
    
        svg {
            fill: var(--red);
            width: 16px;
            height: 16px;
            margin-right: 7px;
        }
    }
`;

const DialogType = ({ children, grayhint }) => {

    const { state } = useContext(FormContext);

    useEffect(() => {
        const Q = gsap.utils.toArray('.form_question');
        gsap.set(Q, { duration: 2, ease: 'power4.out' })
        gsap.to(Q, { opacity: 0 });

        Q.forEach((el, index) => {
            gsap.fromTo('.form_question', { x: -75, autoAlpha: 0 }, { x: 0, autoAlpha: 1 });
        });

        return () => {
            Q.forEach((el, index) => {
                gsap.to('.form_question', { x: -75, autoAlpha: 0 });
            });
        }
    }, [state.step]);

    return (
        <DialogTypeStyled open grayhint={grayhint} className="form_question">
            {children}
        </DialogTypeStyled>
    )
};

export default DialogType;