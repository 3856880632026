import styled, { keyframes } from 'styled-components';


const show = keyframes`
    from {
        opacity: 1;
    }
    
    to {
        opacity: 0;
    }
`;

export const ButtonWrapper = styled.div`
    text-align: center;
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    position: relative;
    
    @media (max-width: 468px) {
        padding: 5px;
    }
    
    button {
        display: block;
        //height: min-content;
        padding: 0;
        background: none;
        border: none;
        cursor: pointer;
        //position: relative;
        //
        //&:first-child::after {
        //    position: absolute;
        //    bottom: 0;
        //    left: 0;
        //    content: "Back";
        //    width: 100%;
        //}
        //
        //&:nth-child(2)::after {
        //    position: absolute;
        //    bottom: 0;
        //    left: 0;
        //    content: "Next";
        //    width: 100%;
        //}
        
        &:disabled {
            cursor: not-allowed ;
            
            svg {
                cursor: inherit;
                
                path {
                    fill: #ffffff4d;
                }
            }
        }
    }
    
    .warning {
        visibility: hidden;
        opacity: 0;
        font-size: 80%;
        text-align: center;
        color: var(--advSvg);
        transition: .2s ease;
    }
    
    .show-warning {
        animation: ${show} 2s ease-in-out; 
    }
`;
