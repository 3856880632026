import React, {useContext, useEffect} from "react"
import FormContext from "./FormContext";
import { useTranslation } from "react-i18next"
import { gsap } from 'gsap';
import { InfoStyles } from "./components/index"
import { ButtonButton } from "./components/Button/ButtonButton"

const Succeed = () => {

    const { state, chooseProduct } = useContext(FormContext);
    const { t } = useTranslation(['valform']);
    let firstName = state.name.split(' ')[0];

    useEffect(() => {
        gsap.to('#anim', { autoAlpha: 1, duration: 1.5 });
    }, []);

    return (
        <>
            <InfoStyles marginTopMinus alignLeft hidden id="anim">
                <p className="summary">
                    {t('valform:valform_utils.thanks')}, {firstName}. {t('valform:valform_overall.i_recieved_your_answers')}
                    <span role="img" aria-label="emoji"> 🚀</span>
                </p>
                <p className="margin40">
                    {t('valform:valform_overall.click_button_below_to_schedule_consultation')}
                </p>
                <ButtonButton onClick={() => chooseProduct('consultation')}>
                    {t('valform:consultation.schedule')}
                </ButtonButton>
            </InfoStyles>
        </>
    );
};


export default Succeed;