import { css } from "styled-components"

export const ButtonStyles = css`
    color: var(--white);
    background: var(--black);
    width: max-content;
    text-align: center;
    font: inherit;
    font-weight: bold;
    text-decoration: none;
    border: 2px solid var(--white);
    border-radius: .2rem;
    font-size: clamp(.8rem, 3.5vw, 1rem);
    cursor: pointer;
    box-shadow: 0 0 38.8px -26px var(--advSvg);
    padding: .5em 1.5em;
    transition: box-shadow .3s ease-in-out, color .3s ease-out, background .3s ease-out;
    will-change: color, background;
    opacity: ${({ invisibleForAnimation }) => invisibleForAnimation ? '0' : 'unset'};
    margin-top: ${({ marginTop }) => marginTop && '2rem'};
    ${props => props.alignLeft && 'margin-left: 0; margin-right: auto'};

    @supports (backdrop-filter: blur(15px)) {
        backdrop-filter: blur(15px);
        background: rgba(0, 0, 0, 0.60);
    }

    &:hover {
        color: var(--black);
        background: var(--white);
    }

    a {
        color: inherit;
        text-decoration: inherit;
    }
`;

    //
    //     background: ${({ filled }) => filled ? "var(--black)" : "var(--white)"};
    // color: ${({ filled }) => filled ? "white!important" : "var(--textTitle)"};
    // width: ${({ widthTooBig }) => widthTooBig && 'max-content'};
