import styled from 'styled-components';

export const CheckboxInput = styled.input`
    //input[type='checkbox']
    --active: var(--primary);
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, .3);
    --border: #BBC1E1;
    --border-hover: #275EFE;
    --background: #fff;
    --disabled: #F6F8FF;
    --disabled-inner: #E1E6F9;
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 21px;
    outline: none;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    border: 1px solid var(--bc, var(--border));
    background: var(--b, var(--background));
    transition: background .3s, border-color .3s, box-shadow .2s;

    &:not(.switch) {
        border-radius: 3px;

        &:after {
            width: 5px;
            height: 9px;
            border: 2px solid var(--active-inner);
            border-top: 0;
            border-left: 0;
            left: 6px;
            top: 3px;
            transform: rotate(var(--r, 20deg));
        }

        &:checked {
            --r: 45deg;
        }
    }

    &:after {
        content: '';
        display: block;
        left: 0;
        top: 0;
        position: absolute;
        transition: transform var(--d-t, .3s) var(--d-t-e, ease), opacity var(--d-o, .2s);
    }

    &:checked {
        --b: var(--active);
        --bc: var(--active);
        --d-o: .3s;
        --d-t: .6s;
        --d-t-e: cubic-bezier(.2, .85, .32, 1.2);
    }

    &:disabled {
        --b: var(--disabled);
        cursor: not-allowed;
        opacity: .9;

        &:checked {
            --b: var(--disabled-inner);
            --bc: var(--border);
        }

        & + label {
            cursor: not-allowed;
        }
    }

    &:hover {
        &:not(:checked) {
            &:not(:disabled) {
                --bc: var(--border-hover);
            }
        }
    }

    &:focus {
        box-shadow: 0 0 0 var(--focus);
    }

    &:not(.switch) {
        width: 21px;

        &:after {
            opacity: var(--o, 0);
        }

        &:checked {
            --o: 1;
        }
    }
`;