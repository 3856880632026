import React, { useContext, useEffect } from "react"
import styled from 'styled-components';
import Loader from "../Utils/Loader"
import { useTranslation } from "react-i18next"
import FormContext from "./FormContext"

const ScheduleConsultationStyles = styled.div`
    position: relative;
    width: 100%;
    
    .loader_wrapper {
        position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 0;
    }
`;

const ScheduleConsultation = ({ minWidth = 320, height = 680, url = 'https://calendly.com/panodstrony/konsultacja-projektu' }) => {

    const { t } = useTranslation(['valform']);
    const { resetAnswers } = useContext(FormContext);

    useEffect(() => {
        const head = document.querySelector('head');
        const script = document.createElement('script');
        script.setAttribute(
            'src',
            'https://assets.calendly.com/assets/external/widget.js'
        );
        head.appendChild(script);
        resetAnswers();
    }, []);

    return (
        <ScheduleConsultationStyles>
            <div
                className="calendly-inline-widget"
                data-url={url}
                style={{ minWidth, height, width: '100%', zIndex: '1' }}
            />
            <div className="loader_wrapper">
                <Loader text={t('valform:valform_utils.loading')} />
            </div>
        </ScheduleConsultationStyles>
    );
};

// const ScheduleConsultation = () => {
//     useEffect(() => {
//         const head = document.querySelector('head');
//         const script = document.createElement('script');
//         script.setAttribute(
//             'src',
//             'https://asset-tidycal.b-cdn.net//js/embed.js'
//         );
//         head.appendChild(script);
//     }, [])
//
//         return (
//             <div
//                 id="tidycal-embed"
//                 data-path="adamleszczynsky/30-minute-meeting"
//             />
//         )
//
// }

export default ScheduleConsultation;