import React from 'react';
import BriefQuestion from "../../Questions/BriefQuestion"
import { briefData } from "../../Common/Brief/Common/data-seed"

export function CompanyName({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.website.company_name}
        stateKey="companyName"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function SocialMedia({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.website.social_media_and_website}
        stateKey="socialMedia"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function WebsiteMap({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.website.website_map.question}
        hint={briefData.website.website_map.hint}
        stateKey="websiteMap"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function UniqueAttributes({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.unique_attributes.question}
        hint={briefData.common.unique_attributes.hint}
        stateKey="uniqueAttributes"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function TargetGroup({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.target_group.question}
        hint={briefData.common.target_group.hint}
        stateKey="targetGroup"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function Purpose({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.website.purpose.question}
        hint={briefData.website.purpose.hint}
        stateKey="purpose"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function ConcurrencyWebsites({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.common.concurrency_websites.question}
        hint={briefData.common.concurrency_websites.hint}
        stateKey="concurrencyWebsites"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}

export function ClientsProblems({ currentStep, allSteps }) {
    return <BriefQuestion
        question={briefData.website.clients_problems.question}
        hint={briefData.website.clients_problems.hint}
        stateKey="clientsProblems"
        currentStep={currentStep}
        allSteps={allSteps}
    />
}