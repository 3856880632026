import React, {useContext} from "react";
import {LeftArrow, RightArrow} from "../../../SVG";
import {ButtonWrapper} from "./ButtonWrapper";
import FormContext from "../../FormContext";
import { useTranslation } from "react-i18next"

const BackFirstQuestAndNext = ({ product, step, nextStepDisabled }) => {

    const { backToFirstQuestion, nextStep } = useContext(FormContext);
    const { t } = useTranslation(['valform']);

    return (
        <ButtonWrapper id="backFirstQuestAndNext">
            <button className="type_website_type" onClick={() => backToFirstQuestion(product, step)} title={t('valform:valform_utils.prev_step_hint')}>
                <LeftArrow/>
            </button>
            <button disabled={nextStepDisabled} onClick={nextStep} title={t('valform:valform_utils.next_step_hint')}>
                <RightArrow/>
            </button>
        </ButtonWrapper>
)};

export default BackFirstQuestAndNext;