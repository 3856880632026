import React, { useContext } from "react"
import FormContext from "../FormContext";
import PriceSummaryContent from "../components/PriceSummaryContent"
import roundOffMoney from "../../../utils/roundOffMoney"

const WebsitePriceSummary = () => {

    const { state, priceWebsiteFixedCosts, priceWebsiteMultipliers, priceWebsiteCondition } = useContext(FormContext);

    const pricePLN = (priceWebsiteFixedCosts * priceWebsiteMultipliers).toFixed(0);
    state.notRoundedPrice = pricePLN;
    state.totalPrice = roundOffMoney(pricePLN);
    state.finalProduct = state.product;
    state.websiteTotalPrice = state.totalPrice;

    return (
        <PriceSummaryContent Condition={priceWebsiteCondition} productType={'website'} />
    )
};

export default WebsitePriceSummary;