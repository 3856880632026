import React, { useContext } from "react"
import styled from 'styled-components';
import FormContext from "../FormContext";
import Dialog from "../components/Dialog";
import { NavigationWrapper, Reset, Pagination, NavigateSteps } from "../components/Navigation/index";
import ScrollProgressIndicator from "../../ScrollProgressIndicator"

const ScrollProgressIndicatorWrapper = styled.div`
    position: relative;
`;

const ChooseAnswer = ({ children, firstQuestion, introQuestion, backToWebsiteType, targetValue, maxValue, noProgressIndicator }) => {

    const { getAllSteps, state } = useContext(FormContext);

    return (
        <>
            <Dialog className="animate" state={state}>
                {children}
            </Dialog>
            <ScrollProgressIndicatorWrapper>
            </ScrollProgressIndicatorWrapper>
            <NavigationWrapper
                onTop={
                    noProgressIndicator ? null : <ScrollProgressIndicator targetValue={targetValue} maxValue={maxValue} />
                }
            >
                <Reset />
                { (!firstQuestion && !backToWebsiteType && !introQuestion) && <NavigateSteps prevAndNext /> }
                { firstQuestion && <NavigateSteps backFirstQuestionAndNext /> }
                { backToWebsiteType && <NavigateSteps backToWebsiteType /> }
                { introQuestion && <NavigateSteps backFirstQuestionAndNextDisabled /> }
                {/*{ introQuestion && <BackFirstQuestAndNext nextStepDisabled /> }*/}
                <Pagination allSteps={getAllSteps()} />
            </NavigationWrapper>
        </>
    );
};

export default ChooseAnswer;