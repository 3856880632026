import React, { useContext } from "react"
import FormContext from "./FormContext"
import { Succeed, Welcome, WhatProduct } from "./index"
import WebsiteController from "./Website/Controller"
import WhatKindOfWebsite from "./WhatKindOfWebsite"
import LandingPageController from "./LandingPage/Controller"
import Loader from "../Utils/Loader"
import DidntGetEmail from "./DidntGetEmail"
import EcommerceController from "./Ecommerce/Controller"
import FailedSubmission from "./FailedSubmission"
import ScheduleConsultation from "./ScheduleConsultation"

const FormController = () => {

    const { state } = useContext(FormContext);

    switch(state.product) {

        default:
            switch(state.step) {
                default:
                    return (
                        <Welcome />
                    )
                case 2:
                    return (
                        <WhatProduct />
                    );
            }

        case 'website_type':
            return (
                <WhatKindOfWebsite nextStepDisabled />
            )

        case 'website':
            return (
                <WebsiteController />
            );

        case 'landing_page':
            return (
                <LandingPageController />
            )

        case 'ecommerce':
            return (
                <EcommerceController />
            );

        case 'consultation':
            return (
                <ScheduleConsultation />
            )

        case 'submit-loading':
            return (
                <Loader />
            )

        case 'submit-succeed':
            return (
                <Succeed />
            );

        case 'submit-failed':
            return (
                <FailedSubmission />
            );

        case 'didnt-get-email':
            return (
                <DidntGetEmail />
            )
        }
};

export default FormController;