import React, {useContext} from "react";
import {LeftArrow, RightArrow} from "../../../SVG";
import {ButtonWrapper} from "./ButtonWrapper";
import FormContext from "../../FormContext";
import { useTranslation } from "react-i18next"

const PrevStepNextStep = ({ disableNextStep }) => {

    const { nextStep, prevStep } = useContext(FormContext);
    const { t } = useTranslation(['valform']);

    return (
        <ButtonWrapper id="prevStepNextStep">
            <button onClick={prevStep} title={t('valform:valform_utils.prev_step_hint')}>
                <LeftArrow />
            </button>
            <button onClick={nextStep} disabled={disableNextStep} title={t('valform:valform_utils.next_step_hint')}>
                <RightArrow />
            </button>
        </ButtonWrapper>
    )};

export default PrevStepNextStep;