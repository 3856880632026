import React, { useContext } from "react"
import { useTranslation } from "react-i18next";
import FormContext from "../FormContext";
import ChooseAnswer from "./ChooseAnswer";
import { RadioInputStyles } from "../components";

export default function ValuationQuestion({ question, answers, stateKey, backToWebsiteType, firstQuestion, wide }) {

    const { t } = useTranslation(['valform']);
    const { state, windowGlobal, handleChange, getAllSteps } = useContext(FormContext);
    const targetValue = getAllSteps('landing_page');

    const cacheJson = JSON.parse(windowGlobal.localStorage.getItem(stateKey));
    const cache = windowGlobal.localStorage.getItem(stateKey);
    if (cache !== null) state[stateKey] = parseFloat(cacheJson)

    return (
        <ChooseAnswer backToWebsiteType={backToWebsiteType} firstQuestion={firstQuestion} wide={wide} targetValue={state.step} maxValue={targetValue}>
            <p className="question">{t(`valform:${question}`)}</p>
            {answers.map(answer => {
                return (
                    <RadioInputStyles
                        wide={wide}
                        key={answer.answerId}
                        selected={cacheJson === answer.price || state[stateKey] === answer.price }
                    >
                        <button
                            className="input_answer"
                            type="radio"
                            id={answer.answerId}
                            name={stateKey}
                            onClick={handleChange(stateKey)}
                            value={answer.price}
                            tabIndex="0"
                            aria-label={t(`valform:${question}`)}
                        />
                        {/*<input*/}
                        {/*    className="input_answer"*/}
                        {/*    type="radio"*/}
                        {/*    id={answer.answerId}*/}
                        {/*    name={stateKey}*/}
                        {/*    onChange={handleChange(stateKey)}*/}
                        {/*    value={answer.price}*/}
                        {/*    role="button"*/}
                        {/*    tabIndex="0"*/}
                        {/*/>*/}
                        <label htmlFor={answer.answerId}>
                            {t(answer.answer)}
                        </label>
                    </RadioInputStyles>
                )})}
        </ChooseAnswer>
    )
};