import React, { useContext } from "react"
import { useTranslation } from "react-i18next";
import FormContext from "../FormContext";
import OverallContent from "../components/OverallContent"

const LandingPageOverall = ({ functionName }) => {

    const { i18n } = useTranslation(['valfor']);
    const locale = i18n.language;
    const { state, submitForm, dollarPrice } = useContext(FormContext);
    const { name, email, notes, product, totalPrice, wantSubscribeToNewsletter, priceLandingPageGoal, multiplierLandingPageCMS, multiplierLandingPageProject, companyName, socialMedia, targetGroup, brandResources, designTastes, uniqueAttributes, anythingElse } = state;

    const onSubmit = (data) => {

        if (wantSubscribeToNewsletter && locale === 'pl') {
            const functionOptions = {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                body: JSON.stringify({
                    email,
                    name
                }),
            };

            fetch(`/.netlify/functions/subscribeToNewsletter`, functionOptions)
                .then(response => console.log(response))
                .then(data=>{
                    console.log(data);
                    return data;
                })
                .catch(err => {
                    console.error('There was an ERROR!!!',err)
                });
        }

        const city = data.city;
        const functionPath = `/.netlify/functions/${functionName}`;
        const functionOptions = {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE'
            },
            body: JSON.stringify({
                wantSubscribeToNewsletter,
                city,
                dollarPrice,
                name,
                email,
                notes,
                locale,
                product,
                totalPrice,
                priceLandingPageGoal,
                multiplierLandingPageProject,
                multiplierLandingPageCMS,
                companyName,
                targetGroup,
                socialMedia,
                brandResources,
                designTastes,
                uniqueAttributes,
                anythingElse,
            }),
        };

        Promise.all([
            fetch(functionPath, functionOptions)
                .then(response => {
                    if (response.ok) {
                      submitForm('submit-succeed');
                    } else {
                      submitForm('submit-failed');
                    }
                })
                .catch(err => {
                    submitForm('submit-failed')
                    console.log(err);
                    alert('Wystąpił błąd. Spróbuj ponownie lub skontakuj się przy pomocy drugiego formularza (podstrona "kontakt"');
                })
        ])
          .then(function(response) {
              console.log(response.ok);
          })

        submitForm('submit-loading');
    };

    return (
        <OverallContent onSubmit={onSubmit} />
    );
};


export default LandingPageOverall;