import React, { useContext, useEffect } from "react"
import FormContext from "./FormContext";
import { useTranslation } from "react-i18next"
import { gsap } from 'gsap';
import { InfoStyles } from "./components/index"
import { ButtonLink } from "./components/Button/ButtonLink"

const FailedSubmission = () => {

    const { state } = useContext(FormContext);
    const { t, i18n } = useTranslation(['valform']);
    const locale = i18n.language;
    let firstName = state.name.split(' ')[0];

    useEffect(() => {
        gsap.to('.animate', { autoAlpha: 1, duration: 1.5});
    }, []);

    return (
      <>
          <InfoStyles marginTopMinus hidden className="animate">
              <p className="summary">
                  {t('valform:submit_failed.sorry')}, {firstName}. {t('valform:submit_failed.was_problem')}
              </p>
              <p>
                  {t('valform:submit_failed.if_you_want_to_talk')}
              </p>
              <p>
                  {t('valform:submit_failed.plz_let_me_know')}
              </p>
              <ButtonLink alignLeft marginTop to="/kontakt">
                  {locale === 'pl' && 'kontakt'}
                  {locale === 'en' && 'contact'}
              </ButtonLink>
          </InfoStyles>
      </>
    );
};

export default FailedSubmission;