import React, { useContext } from "react"
import { useTranslation } from "react-i18next";
import FormContext from "./FormContext";
import { RadioInputStyles } from './components/index';
import ChooseAnswer from "./Questions/ChooseAnswer"
import AdditionalInfo from "./components/AdditionalInfo"

const WhatKindOfWebsite = () => {

    const { t } = useTranslation(['valform']);
    const { chooseProduct } = useContext(FormContext);

    return (
        <>
            <ChooseAnswer introQuestion noProgressIndicator>
                <p className="question">
                    {t('valform:valform_intro.what_kind_of_website')}
                </p>
                <RadioInputStyles wide>
                    <button
                        className="input_answer"
                        type="radio"
                        id="website"
                        name="website"
                        placeholder="e-commerce"
                        onClick={() => chooseProduct('website')}
                        aria-label={t('valform:valform_intro.traditional_website')}
                    />
                    <label htmlFor="website">
                        {t('valform:valform_intro.traditional_website')}
                    </label>
                </RadioInputStyles>
                <RadioInputStyles wide>
                    <button
                        className="input_answer"
                        type="radio"
                        id="landing_page"
                        name="landing_page"
                        onClick={() => chooseProduct('landing_page')}
                        aria-label="Landing Page"
                    />
                    <label htmlFor="landing_page">
                        Landing Page *
                    </label>
                </RadioInputStyles>
                <AdditionalInfo>* {t('valform:valform_intro.landing_page_desc')}</AdditionalInfo>
            </ChooseAnswer>
        </>
    )};

export default WhatKindOfWebsite;