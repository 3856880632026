import React, { useContext } from "react"
import FormContext from "../FormContext";
import { mapPriceWithGoal  } from "./Valuation/data-seed"
import PriceSummaryContent from "../components/PriceSummaryContent"
import roundOffMoney from "../../../utils/roundOffMoney"

const LandingPagePriceSummary = () => {

    const { state, priceLandingPageMultipliers, priceLandingPageCondition } = useContext(FormContext);

    const goalPrice = mapPriceWithGoal(state);
    const pricePLN = (goalPrice * priceLandingPageMultipliers).toFixed(0);
    state.totalPrice = roundOffMoney(pricePLN)
    state.finalProduct = state.product;

    return (
        <PriceSummaryContent Condition={priceLandingPageCondition} productType={'landing_page'} />
    )
};

export default LandingPagePriceSummary;