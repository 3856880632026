import React, { useContext } from "react"
import { useTranslation } from "react-i18next";
import FormContext from "../FormContext";
import TypeAnswer from "./TypeAnswer"
import scrollTo from "gatsby-plugin-smoothscroll"
import { TextInputStyles } from '../components';
import { ButtonButton } from "../components/Button/ButtonButton"
import TextareaAutosize from 'react-textarea-autosize';
import ButtonTip from "../components/ButtonTip";

const BriefQuestion = ({ question, hint, stateKey, currentStep, allSteps }) => {

    const { t } = useTranslation(['valform']);
    const { state, windowGlobal, handleChangeTextarea, nextStep } = useContext(FormContext);

    const cache = windowGlobal.localStorage.getItem(stateKey);
    if (cache) state[stateKey] = cache;

    return (
        <>
            <TypeAnswer grayhint currentStep={currentStep} allSteps={allSteps}>
                <TextInputStyles wide>
                    <label htmlFor={stateKey}>
                        {t(`valform:${question}`)}
                        {hint &&
                        <small className="hint" dangerouslySetInnerHTML={{ __html: t(`valform:${hint}`) }}>
                            {/*{t(`valform:${hint}`)}*/}
                        </small>
                        }
                    </label>
                    <TextareaAutosize
                        placeholder={t('valform:valform_utils.type_here_or_leave_blank')}
                        name={stateKey}
                        onChange={handleChangeTextarea(stateKey)}
                        defaultValue={state[stateKey] || cache}
                        rows="1"
                    />
                    <ButtonButton alignLeft marginTop send onClick={() => {
                        nextStep();
                        scrollTo('body');
                    }}>
                        {t('valform:valform_utils.next')}
                    </ButtonButton>
                    <ButtonTip visible={state[stateKey]}/>
                </TextInputStyles>
            </TypeAnswer>
        </>
    );
};


export default BriefQuestion;