import React from "react"
import { eCommerceData as data } from "./data-seed"
import ValuationQuestion from "../../Questions/ValuationQuestion"

export function ProductsCount() {
    return <ValuationQuestion
        question="valform_ecommerce.ProductsCount"
        answers={data.answersProductsCount}
        stateKey="multiplierEcommerceProductsCount"
        firstQuestion
        wide
    />
}

export function ControlProductsDetails() {
    return <ValuationQuestion
        question="valform_ecommerce.ControlProductsDetails"
        answers={data.answersControlProductsDetails}
        stateKey="priceEcommerceControlProductsDetails"
        wide
    />
}

export function AccountRegistration() {
    return <ValuationQuestion
        question="valform_ecommerce.AccountRegistration"
        answers={data.answersAccountRegistration}
        stateKey="priceEcommerceAccountRegistration"
    />
}

export function Discounts() {
    return <ValuationQuestion
        question="valform_ecommerce.Discounts"
        answers={data.answersDiscounts}
        stateKey="priceEcommerceDiscounts"
    />
}

export function StockAvailability() {
    return <ValuationQuestion
        question="valform_ecommerce.StockAvailability"
        answers={data.answersStockAvailability}
        stateKey="priceEcommerceStockAvailability"
        wide
    />
}

export function LabelProducts() {
    return <ValuationQuestion
        question="valform_ecommerce.LabelProducts"
        answers={data.answersLabelProducts}
        stateKey="priceEcommerceLabelProducts"
    />
}

export function Integration() {
    return <ValuationQuestion
        question="valform_ecommerce.Integration"
        answers={data.answersIntegration}
        stateKey="priceEcommerceIntegration"
    />
}

export function Variants() {
    return <ValuationQuestion
        question="valform_ecommerce.Variants"
        answers={data.answersVariants}
        stateKey="priceEcommerceVariants"
        wide
    />
}

export function SearchBar() {
    return <ValuationQuestion
        question="valform_ecommerce.SearchBar"
        answers={data.answersSearchBar}
        stateKey="priceEcommerceSearchBar"
    />
}