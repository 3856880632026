import React from 'react';
import styled from "styled-components"

const StyledNavigation = styled.div`
    position: absolute;
        bottom: 0;
        left: 0;
    width: 100%;
    background: var(--primary);
    //background: var(--black);
    
    .inner_wrapper {
        position: relative;
        display: flex;
        justify-content: space-between;
        grid-gap: 3rem;

        @media (min-width: 768px) {
            width: min-content;
            margin: 0 auto;
        }
    }
    
    @media (max-width: 768px) {
        top: 0;
        bottom: unset;
        border-radius: 0;
    } 
`;

export default function NavigationWrapper({ children, onTop }) {
    return (
        <StyledNavigation>
            {onTop}
            <div className="inner_wrapper">
                {children}
            </div>
        </StyledNavigation>
    )
}
