import React, { useContext, useEffect } from "react"
import styled from 'styled-components';
import FormContext from "./FormContext";
import { useTranslation } from "react-i18next"
import { gsap } from 'gsap';
import { PureButton } from "../Button/PureButton";

const StyledWrapper = styled.div`
    color: white;
    max-width: 65ch;
    flex-direction: column;
    opacity: 0;
    margin: 2rem 1rem;
    
    a {
        background: none;
        border: none;
        color: var(--primary);
        font: inherit;
        cursor: pointer;
        padding: 0;
        text-decoration: none;
    }
    
    .button_wrapper {
        margin-top: 2rem;
    }
`;

const DidntGetEmail = () => {

    const { state, chooseProduct, dollarPrice, BriefWebsiteUrlPL, BriefLandingPageUrlPL, BriefEcommerceUrlPL, BriefWebsiteUrlEN, BriefLandingPageUrlEN, BriefEcommerceUrlEN
    } = useContext(FormContext);
    const { i18n } = useTranslation();
    const locale = i18n.language;
    const { name, finalProduct, totalPrice } = state;
    let firstName = name.split(' ')[0];

    let briefLink;
    if (finalProduct === 'ecommerce') {
        if (locale === 'pl') {
            briefLink = BriefEcommerceUrlPL;
        }
        if (locale === 'en') {
            briefLink = BriefEcommerceUrlEN;
        }
    }
    if (finalProduct === 'website') {
        if (locale === 'pl') {
            briefLink = BriefWebsiteUrlPL;
        }
        if (locale === 'en') {
            briefLink = BriefWebsiteUrlEN;
        }
    }
    if (finalProduct === 'landing_page') {
        if (locale === 'pl') {
            briefLink = BriefLandingPageUrlPL;
        }
        if (locale === 'en') {
            briefLink = BriefLandingPageUrlEN;
        }
    }

    console.log(briefLink);

    useEffect(() => {
        gsap.to('.animate', { autoAlpha: 1, duration: 1.5});
    }, [locale]);

    return (
        <>
            <StyledWrapper className="animate">
            {locale === 'pl' &&
                <>
                    <h1>Witaj, {firstName}.</h1>
                    <p>Kwota podana przez kalkulator wyniosła <strong>{totalPrice} zł</strong>. Pamiętaj, że cena ta może różnić się od ceny ostatecznej i nie stanowi ona oferty.</p>
                    <p>Jeżeli chcesz otrzymać pełnoprawną ofertę, poniżej znajduje się link do formularza, w którym będziesz mógł opowiedzieć mi więcej o swojej działalności. Wszystkie szczegółowe informacje na ten temat znajdziesz klikając w poniższy link.</p>
                    <a href={briefLink} target="_blank" rel="noopener noreferrer">
                        PRZEJDŹ DO FORMULARZA
                    </a>
                    <footer>
                        <p>W razie jakichkolwiek pytań czy wątpliwości pozostaję do Twojej dyspozycji. Możesz odpisać na tego maila, lub, jeśli wolisz, skontaktować się ze mną telefonicznie: <a href="tel:608709237">+48 608 709 237</a></p>
                        <p>Pozdrawiam, <br/>
                          Adam / panodstrony.pl</p>
                    </footer>
                    <div className="button_wrapper">
                        <PureButton onClick={() => chooseProduct('submit-succeed')}>wróć</PureButton>
                    </div>
                </>
            }
            {locale === 'en' &&
                <>
                    <h1>Hello, {firstName}.</h1>
                    <p>The amount given by the calculator was <strong>{totalPrice / dollarPrice.toFixed()}$</strong>. Please note that this price may differ from the final price and does not constitute an offer.</p>
                    <p>If you want to receive a full-fledged offer, below is a link to the form where you can tell me more about your business. You can find all the detailed information on this subject by clicking on the link below.</p>
                    <a href={briefLink} target="_blank" rel="noopener noreferrer">
                        PRZEJDŹ DO FORMULARZA
                    </a>
                    <footer>
                        <p>In case of any questions or doubts, I remain at your disposal. You can reply to this e-mail or, if you prefer, contact me by the phone: <a href="tel:608709237">+48 608 709 237</a></p>
                        <p>Greetings, <br/>
                            Adam / panodstrony.pl</p>
                    </footer>
                    <div className="button_wrapper">
                        <PureButton onClick={() => chooseProduct('submit-succeed')}>back</PureButton>
                    </div>
                </>
            }
            </StyledWrapper>
        </>
    );
};


export default DidntGetEmail;
