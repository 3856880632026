import React from "react"
import { landingPageData as data } from "./data-seed"
import ValuationQuestion from "../../Questions/ValuationQuestion"
import ValuationQuestionString from "../../Questions/ValuationQuestionString"

export function Goal() {
    return <ValuationQuestionString
        question="valform_website.Goal"
        answers={data.answersGoal}
        stateKey="priceLandingPageGoal"
        stringState
        backToWebsiteType
        wide
    />
}

export function Cms() {
    return <ValuationQuestion
        question="valform_website.CMS"
        answers={data.answersCMS}
        stateKey="multiplierLandingPageCMS"
    />
}

export function Project() {
    return <ValuationQuestion
        question="valform_website.Project"
        answers={data.answersProject}
        stateKey="multiplierLandingPageProject"
    />
}