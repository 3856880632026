import styled from 'styled-components';

export const RadioInputStyles = styled.div`
    ${props => props.hidden? 'opacity: 0;' : ''};
    position: relative;
    display: flex;
    margin: 0 auto 15px auto;
        align-items: center;
    text-align: left;
    width: ${({ wide }) => wide ? '33%' : '25%'};
    border: ${({ selected }) => selected ? '2px solid var(--formRadioInputWrapperBorder)' : '1px solid #2e2541' };
    border-radius: 5px;
    min-width: 140px;
    transition: color .3s ease-in-out, background .3s ease-in-out;
    cursor: pointer;
    
    @supports(backdrop-filter: blur(15px)) {
        backdrop-filter: blur(5px);
        background: ${({ selected }) => selected ? '#4a4a6455' : '#11042d99' };
    }
    
    
    &:last-child {
        margin: 0 auto;
    }
    
    // @media (min-width: 981px) {
    //     width: ${({ wide }) => wide ? '75%' : '40%' };
    //     max-width: ${({ wide }) => wide ? '450px' : '150px' };
    // }

    @media (max-width: 980px) {
        width: ${({ wide }) => wide ? '75%' : '20%' };
        max-width: ${({ wide }) => wide ? '400px' : '150px' };
    }

    @media (max-width: 768px) {
        width: ${({ wide }) => wide ? '45%' : '20%' };
    }

    @media (max-width: 600px) {
        width: ${({ wide }) => wide ? '65%' : '20%' };
    }
    
    @media (max-width: 460px) {
        width: ${({ wide }) => wide ? '85%' : '45%' };
    }
    
    @media (max-width: 380px) {
        width: ${({ wide }) => wide ? '85%' : '85%' };
    }
    
    &:first-child {
        margin-top: 20px;
    }
    
    &:hover {
        background: #11141d;

        &:nth-of-type(1):before,
        &:nth-of-type(2):before,
        &:nth-of-type(3):before,
        &:nth-of-type(4):before,
        &:nth-of-type(5):before,
        &:nth-of-type(6):before,
        &:nth-of-type(7):before  {
            background: var(--primary);
            color: var(--white);
        }
    }
    
    
    &:nth-of-type(1):before {
        content: 'A';
    }
    
    &:nth-of-type(2):before {
        content: 'B';
    }
    
    &:nth-of-type(3):before {
        content: 'C';
    }
    
    &:nth-of-type(4):before {
        content: 'D';
    }
    
    &:nth-of-type(5):before {
        content: 'E';
    }
    
    &:nth-of-type(6):before {
        content: 'F';
    }
    
    &:nth-of-type(7):before {
        content: 'G';
    }
    
    &:nth-of-type(1):before,
    &:nth-of-type(2):before,
    &:nth-of-type(3):before,
    &:nth-of-type(4):before,
    &:nth-of-type(5):before,
    &:nth-of-type(6):before,
    &:nth-of-type(7):before {
        position: absolute;
            left: 8px;
            top: 50%;
        display: flex;
            justify-content: center;
            align-items: center;
        height: 22px;
        width: 22px;
        background: ${props => props.selected ? 'var(--primary)' : 'var(--white)'};
        color: ${props => props.selected ? 'var(--white)' : 'var(--primary)'};
        border-radius: .35em;
            font-size: ${({ selected }) => selected ? '100%' : '80%'};
            font-weight: ${({ selected }) => selected ? 'bold' : 'normal'};
            line-height: 1;
        transform: translateY(-50%);
        transition: color .3s ease-out, background .3s ease-out;
        text-transform: lowercase;
        ${props => props.selected && 'content: "✓"'};
    }

    input[type="radio" i] {
        visibility: hidden;
        width: 0;
        background-color: initial;
        cursor: pointer;
        box-sizing: border-box;
        padding: initial;
        border: initial;
    }

    input[type="textarea"] {
        width: 100%;
    }

    label {
        position: relative;
            z-index: auto;
        display: flex;
            align-items: center;
        color: var(--white);
        ${props => props.selected && 'color: var(--white)'};
        width: 100%;
        word-break: keep-all;
        line-height: 1.5;
        // padding-left: 3em;
        // padding-top: .4em;
        // padding-bottom: .4em;
        // padding-right: .5em;
        padding: .4em .5em .4em 3em;
        cursor: pointer;
        transition: color .3s ease-in-out;
    }

    input, textarea {
        width: 100%;
        background: var(--formInputBg);
        border: var(--formInputBorder);
        color: white;
        padding: 8px 10px;

        &:focus {
            outline: none;
            box-shadow: 0 0 5px 2px #1589BA;
        }

        [type="radio"]:checked {
            transform: scale(2);
        }
    }

    input[type="button"] {
        border: 2px solid red;
        position: absolute;
        width: 100%;
        height: 100%;
    }
    
    button {
        position: absolute;
        width: 100%;
        height: 100%;
        border-bottom: none;
        padding: 0;
        
        ::before {
            content: none;
        }
    }
`

// background: ${({ selected }) => (selected ? '#4a4a64' : 'rgba(38,38,65,0.8)')};