import React, { useContext, useEffect, useState } from "react"
import FormContext from "../FormContext"
import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { ErrorMessage } from "@hookform/error-message"
import { gsap } from "gsap"
import { InfoStyles } from "./styles/InfoStyles"
import { ButtonButton } from "./Button/ButtonButton"
import { CheckboxInput } from "./styles/CheckboxInput"
import { TextInputStyles } from "./styles/TextInputStyles"
import Error from "../../../../static/error.svg"
import { NavigationWrapper, Pagination, PrevStepNextStep, Reset } from "./Navigation"

const OverallContent = ({ onSubmit }) => {

    const { windowGlobal, state, setState, dollarPrice } = useContext(FormContext);
    // const [isToggled, setIsToggled] = useState(false);
    const [isToggled, setIsToggled] = useState(() => {
        const cache = windowGlobal.localStorage.getItem('wantSubscribeToNewsletter');
        return cache === "true";
    });

    useEffect(() => {
        gsap.to('.animate', { autoAlpha: 1, duration: 1.5});
        setState({
            ...state,
            wantSubscribeToNewsletter: isToggled
        });
    },[]);

    const { handleSubmit, register, formState: { errors } } = useForm();
    const { t, i18n } = useTranslation(['valform']);
    const locale = i18n.language;

    const subscribeToNewsletter = register('subscribeToNewsletter');

    return (
        <>
            <InfoStyles hidden className="animate">
                <h3 className="summary_heading">
                    {t('valform:valform_personal_data.summary')}
                </h3>
                <p>
                    {t('valform:valform_personal_data.your_name')}: <b>{state.name}</b>
                </p>
                <p>
                    {t('valform:valform_personal_data.your_email')}: <b>{state.email}</b>
                </p>
                {state.notes &&
                <div>
                    <p>{t('valform:valform_personal_data.extra_msg')}: <span role="img" aria-label="emoji"> ✅</span></p>
                </div>
                }
                <p>
                    {locale === 'pl' && <>Całkowita cena: <b>{state.totalPrice} zł</b></>}
                    {locale === 'en' && <>Total price: <b>{state.totalPrice / dollarPrice.toFixed()} $</b></>}
                </p>
                    {locale === 'pl' &&
                        <div className="subscribe_to_newsletter">
                            <CheckboxInput
                                type="checkbox"
                                id="subscribeToNewsletter"
                                name="subscribeToNewsletter"
                                checked={isToggled}
                                onChange={(e) => {
                                    subscribeToNewsletter.onChange(e);
                                    setIsToggled(!isToggled);
                                    setState({
                                        ...state,
                                        wantSubscribeToNewsletter: !isToggled
                                    })
                                    windowGlobal.localStorage.setItem('wantSubscribeToNewsletter', e.target.checked);
                                }}
                                ref={subscribeToNewsletter.ref}
                            />
                            <label className="small" htmlFor="subscribeToNewsletter">
                                Chcę dołączyć do newslettera
                            </label>
                            <ErrorMessage
                                errors={errors}
                                name="consent"
                                message={t('valform:valform_personal_data.give_consent')}
                                render={({ message}) => <small><Error/> {message}</small>}
                            />
                        </div>
                    }
                <div className="checkbox_wrapper">
                    <CheckboxInput
                        className="city"
                        type="text"
                        name="city"
                        {...register('city')}
                    />
                    <CheckboxInput
                        type="checkbox"
                        id="consent"
                        name="consent"
                        style={{ border: errors.consent && '2px solid var(--red)' }}
                        className={ errors.consent && 'shake' }
                        {...register('consent', { required: true})}
                    />
                    <label className={ `small ${errors.consent ? 'shake' : ''}` } htmlFor="consent" >
                        {t('valform:valform_personal_data.consent')}
                    </label>
                    <ErrorMessage
                        errors={errors}
                        name="consent"
                        message={t('valform:valform_personal_data.give_consent')}
                        render={({ message}) => <small><Error/> {message}</small>}
                    />
                </div>
                <TextInputStyles noPadding>
                    <ButtonButton
                        alignLeft
                        send
                        onClick={handleSubmit(onSubmit)}
                    >
                        {t('valform:valform_utils.send')}
                    </ButtonButton>
                </TextInputStyles>
            </InfoStyles>
            <NavigationWrapper>
                <Reset />
                <PrevStepNextStep disableNextStep />
                <Pagination visualHidden/>
            </NavigationWrapper>
        </>
    )
}

export default OverallContent;